import React, { useEffect } from "react";
import NFTGameTemplate from "../../components/templates/register-game";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function NFTGamePage(props) {
  return <NFTGameTemplate />;
}

export default NFTGamePage;
