import React, { useEffect } from "react";
import HomeTemplate from "../../components/templates/home";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function Home(props) {
    return (
        <SiteLayout noStyle>
            <HomeTemplate />
        </SiteLayout>
    );
}

export default Home;
