import React, { useEffect } from "react";
import MatchResultPage from "../../components/templates/match-result";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function MatchResult(props) {
  return (
    <SiteLayout>
        <MatchResultPage />
    </SiteLayout>
);
}

export default MatchResult;
