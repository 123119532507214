import React, {useState, useEffect, useContext} from 'react'
import { Dropdown } from 'antd'
import './style.scss'
import ThemeContext from '../../../hook/ThemeContext';

function CustomDropdown({ children, ...props }) {
  const { theme, setTheme } = useContext(ThemeContext);
    useEffect(() => {
        const savedThemeLocal = localStorage.getItem("globalTheme");
    
        if (!!savedThemeLocal) {
          setTheme(savedThemeLocal);
        }
      }, []);
    
        return (
            <Dropdown
                {...props}
                overlayClassName={`theme--${theme} ${props?.overlayClassName ?? ''}`}
            >
                { children }
            </Dropdown>
        )
}

export default CustomDropdown
