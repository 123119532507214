import {
    approveWallet,
    getUser,
    handleResultTransaction,
    handleTransactionExternalWallet,
    approveAllWallet,
    sleep,
    getMaxBalance,
    queryContractTerra,
    RPC_BSC,
    getBalanceToken
} from "../ultis/common";
import {
  claimRedeemPoints,
  buyPoints
} from "../api";
import { MsgExecuteContract } from "@terra-money/terra.js";
import Web3 from "web3";
import marketABI from "../constants/ABI/market.json";
import tokenABI from "../constants/ABI/token.json";
import claimMAGContractABI from "../constants/ABI/claimMAG.json"
import { ACTION_SEND_TRANSACTION, PRICE_BUY_POINTS, CONTRACT_ADDRESS_CLAIM_MAG } from "../constants";
import { useUIContext } from "../hook/AppContext";
import {
    SignBytesFailed,
    Timeout,
    useConnectedWallet,
    UserDenied,
    TxFailed
} from "@terra-money/wallet-provider";
import { message } from "antd";
import miniGameContractABI from '../constants/ABI/miniGameContract.json'
import { useWeb3React } from '@web3-react/core';

const web3 = new Web3()

const useBlockchainFunc = () => {
    const {
        statusTextModal,
        setStatusTextModal,
        setCallBackFunc,
        isAuthenticatedUser,
        setIsShowingLoginPopup,
        chains,
        setStatusModal,
        history,
        gameInfo,
        leageInfo
    } = useUIContext();

    const { library, activate } = useWeb3React()

    const connectedWallet = useConnectedWallet();
    const user = getUser();
    
    

    const checkRegister = async (address, callback = Function()) => {
            try {
                const web3 = new Web3(RPC_BSC);
                const gameNFTCont = new web3.eth.Contract(
                    miniGameContractABI,
                    process.env.REACT_APP_NFT_GAME_REGISTER
                );
                const resData = await gameNFTCont.methods
                    .isAddressJoinedSeasonTCP(address || getUser().address, gameInfo?.game_index)
                    .call();
                    return resData
            } catch (err) {
                console.log(err);
            }
     
        return false;
    };

    

    const checkAccept = async (
        address,
        tokenAddress,
        tokenId,
        callback = Function()
    ) => {
        try {
            const web3 = new Web3(RPC_BSC);
            const marketContract = new web3.eth.Contract(
                marketABI,
                process.env.REACT_APP_MARKET_CONTRACT_ADDRESS
            );
            const resData = await marketContract.methods
                .isAcceptable(address, tokenAddress, tokenId)
                .call();

            return true;
        } catch (err) {
            console.log(err);
        }
    };

    const checkLogin = () => {
        if (!isAuthenticatedUser) {
            setIsShowingLoginPopup(true);
            return false;
        }
        return true;
    };

    const checkAlowance = async (action, contract, contract_address, query, isItems=false, price= 0) => {
        if (
            [
                ACTION_SEND_TRANSACTION.sale,
                ACTION_SEND_TRANSACTION.acceptOffer,
                ACTION_SEND_TRANSACTION.createBundle,
            ].includes(action) && !isItems
        ) {
            const res = await queryContractTerra(contract_address, {
                approved_for_all: { owner: user.address },
            });
            if (!res.operators.find((el) => el.spender === user.chainInfo.market_contract)) {
                await signTxMsg(contract_address, {
                    approve_all: {
                        operator: user.chainInfo.market_contract,
                    },
                }, ACTION_SEND_TRANSACTION.allow);
            }
            return true
        } else {
            const contract = isItems ? contract_address : user.chainInfo.token_contract
            const res = await queryContractTerra(
                contract,
                {
                    allowance: {
                        owner: user.address,
                        spender: user.chainInfo.market_contract,
                    },
                }
            );
            
            if (+res.allowance <= price) {
                const amount = 1000000000 + Number(price)
                await signTxMsg(contract, {
                    increase_allowance: {
                        amount: amount+"",
                        spender: user.chainInfo.market_contract,
                    },
                }, ACTION_SEND_TRANSACTION.allow);
            }
            return true
        }
    };

    const excQueryContract = async (contract, query, action, contractNft,isItems, price) => {
        try {
            const allow = await checkAlowance(action, contract, contractNft, query, isItems,price);
            if (allow) {
                await signTxMsg(contract, query, action);
            }
        } catch (err) {
            throw err;
        }
    };

    const signTxMsg = async (contract, query, action) => {
        try {
            if(user?.chainInfo?.chain_id != connectedWallet.network.chainID) {
                throw(`Please change network on Terra to ${user?.chainInfo?.name} to continue`)
            }
            const nextTxResult = await connectedWallet.post({
                msgs: [new MsgExecuteContract(user.address, contract, query)],
            });
            if(action !== ACTION_SEND_TRANSACTION.allow) {
              await handleResultTransaction({
                  txHash: nextTxResult.result.txhash,
                  action,
                  chain: user.chainInfo._id,
              });
            }
            return true;
        } catch (error) {
            message.error(error.message || error);
            if (error instanceof UserDenied) {
                throw('User Denied');
              } else if (error instanceof Timeout) {
                throw('Timeout');
              } else if (error instanceof SignBytesFailed) {
                throw('Sign Bytes Failed');
              } else if (error instanceof TxFailed) {
                throw('Sign Bytes Failed');
              }
            throw error
        }
    };

    const registerNFTGame = async (
        address,
        callback = Function()
    ) => {
        try {
        const priceT = gameInfo?.seasons[0]?.price || 0
        setCallBackFunc(() => callback);
        setStatusTextModal({
            ...statusTextModal,
            pending: "Transaction pending",
            success: "Register successfully!",
            fail: "Failed to register",
            checkBalance: "Insufficient funds!",
        });
        setStatusModal({ isPending: true });
        const web3 = new Web3(RPC_BSC);
        const gameNFTCont = new web3.eth.Contract(
            miniGameContractABI,
            process.env.REACT_APP_NFT_GAME_REGISTER
        );
        if(priceT) {
            const balance = await getMaxBalance(
                getUser()?.address,
                process.env.REACT_APP_USDT_CONTRACT_ADDRESS
            );
            if (balance.token < priceT) {
                await sleep(1000);
                setStatusModal({ isPending: false, isCheckBalance: true });
                return false;
            }
        }
        
        const resData = await gameNFTCont.methods
            .isAddressJoinedSeasonTCP(address,gameInfo?.game_index)
            .call();
          
        if (resData) {
            await sleep(1000);
            setStatusModal({ isPending: false, isNotify: true });
            callback();
            history.push('/nft-game/home?tab=MyDeck')
            return true;
        }
        let walletAppr = true
        if(priceT) {
            walletAppr = await handleApproveWallet(
                tokenABI,
                process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
                process.env.REACT_APP_NFT_GAME_REGISTER,
                setStatusModal
            );
        }
        if (walletAppr) {
        const approveData = gameNFTCont.methods
            .joinNewestSeasonTCP(gameInfo?.game_index)
            .encodeABI();
        const dataSend = {
            signData: {
                data: approveData,
                from: user.address,
                to: process.env.REACT_APP_NFT_GAME_REGISTER,
            },
        };
        await handleTransactionExWallet(
            1,
            dataSend,
            handleResultTxDone
        )}
        return true;
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const checkTurnCanBuy = async (
        address,
        callback = Function()
    ) => {
        
        try {
            const web3 = new Web3(RPC_BSC);
            const gameNFTCont = new web3.eth.Contract(
                miniGameContractABI,
                process.env.REACT_APP_NFT_GAME_REGISTER
            );
            const resData = await gameNFTCont.methods
                .remainingTurnCanBuy(address || getUser().address, gameInfo?.game_index)
                .call();
            return resData
        } catch (err) {
            console.log(err);
        }
        return 0
    }

    const buyTurnGame = async (
        quantity,
        price,
        callback = Function()
    ) => {
        try {
            setCallBackFunc(() => callback);
            setStatusTextModal({
                ...statusTextModal,
                pending: "Transaction pending",
                success: "Buy successfully!",
                fail: "Failed to buy",
                checkBalance: "Insufficient funds!",
            });
            setStatusModal({ isPending: true });
            const web3 = new Web3(RPC_BSC);
            const gameNFTCont = new web3.eth.Contract(
                miniGameContractABI,
                process.env.REACT_APP_NFT_GAME_REGISTER
            );
            const balance = await getMaxBalance(
                getUser()?.address,
                process.env.REACT_APP_USDT_CONTRACT_ADDRESS
            );
            if (balance.token < price) {
                await sleep(1000);
                setStatusModal({ isPending: false, isCheckBalance: true });
                return false;
            }
            const walletAppr = await handleApproveWallet(
                tokenABI,
                process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
                process.env.REACT_APP_NFT_GAME_REGISTER,
                setStatusModal
            );
            if (walletAppr) {
            const approveData = gameNFTCont.methods
                .buyTurnTCP(quantity,gameInfo?.game_index)
                .encodeABI();
            const dataSend = {
                signData: {
                    data: approveData,
                    from: user.address,
                    to: process.env.REACT_APP_NFT_GAME_REGISTER,
                },
            };
            await handleTransactionExWallet(
                1,
                dataSend,
                handleResultTransaction,
                ACTION_SEND_TRANSACTION.buyTurn

            )}
            return true;
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const redeemPoints = async (
        id,
        callback = Function()
    ) => {
        try {
            setCallBackFunc(() => callback);
            setStatusTextModal({
                ...statusTextModal,
                pending: "Transaction pending",
                success: "Claim successfully!",
                fail: "Failed to claim",
                checkBalance: "Insufficient funds!",
            });
            setStatusModal({ isPending: true });
            const web3 = new Web3(RPC_BSC);
            console.log(process.env.REACT_APP_MINI_GAME);
            const miniGameCont = new web3.eth.Contract(
                miniGameContractABI,
                process.env.REACT_APP_MINI_GAME
            );
            const res =  await claimRedeemPoints({id: id})
            const approveData = miniGameCont.methods
                .redeemPoints(res.id, res.time, web3.utils.toWei(`${res.amount}`, "ether"), res.game_index, res.signature)
                .encodeABI();
            const dataSend = {
                signData: {
                    data: approveData,
                    from: user.address,
                    to: process.env.REACT_APP_MINI_GAME,
                },
            };
            await handleTransactionExWallet(
                1,
                dataSend,
                handleResultTransaction,
                ACTION_SEND_TRANSACTION.redeemPoints

            )
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const buyPoint = async (
        callback = Function()
    ) => {
        try {
            setCallBackFunc(() => callback);
            setStatusTextModal({
                ...statusTextModal,
                pending: "Transaction pending",
                success: "Buy successfully!",
                fail: "Failed to buy",
                checkBalance: "Insufficient funds!",
            });
            setStatusModal({ isPending: true });
            const web3 = new Web3(RPC_BSC);
            const miniGameCont = new web3.eth.Contract(
                miniGameContractABI,
                process.env.REACT_APP_MINI_GAME
            );

            const balance = await getBalanceToken(
                getUser()?.address,
                process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS
            );
            if (balance.token < PRICE_BUY_POINTS) {
                await sleep(1000);
                setStatusModal({ isPending: false, isCheckBalance: true });
            }else {
                const walletAppr = await handleApproveWallet(
                                        tokenABI,
                                        process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
                                        process.env.REACT_APP_MINI_GAME,
                                        setStatusModal
                );
                if (walletAppr) {
                    const res =  await buyPoints()
                    const approveData = miniGameCont.methods
                        .buyPoints(res.id, res.time, res.game_index, res.signature)
                        .encodeABI();
                    const dataSend = {
                        signData: {
                            data: approveData,
                            from: user.address,
                            to: process.env.REACT_APP_MINI_GAME,
                        },
                    };
                    await handleTransactionExWallet(
                        1,
                        dataSend,
                        handleResultTransaction,
                        ACTION_SEND_TRANSACTION.buyPoints

                    )
                }
            } 
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const claimMAG = async( callback = Function()) => {
        try {
            setCallBackFunc(() => callback);
            // setStatusTextModal({
            //     ...statusTextModal,
            //     pending: "Transaction pending",
            //     success: "Claim MAG successfully!",
            //     fail: "Failed to claim MAG",
            // });
            // setStatusModal({ isPending: true });
            const web3 = new Web3(RPC_BSC);
            const claimCont = new web3.eth.Contract(
                claimMAGContractABI,
                CONTRACT_ADDRESS_CLAIM_MAG
            );

            const approveData = claimCont.methods
                .faucet()
                .encodeABI();
            const dataSend = {
                signData: {
                    data: approveData,
                    from: user.address,
                    to: CONTRACT_ADDRESS_CLAIM_MAG,
                },
            };
            await handleTransactionExWallet(
                1,
                dataSend,
                handleResultTransaction,
            )
                
        } catch (err) {
            console.log(err);
            // setStatusModal({ isPending: false, isFailed: true });
        }
    }

    const checkAddressJoinedSeasonLeague = async (
        address,
        callback = Function()
    ) => {
        
        try {
            const web3 = new Web3(RPC_BSC);
            const gameNFTCont = new web3.eth.Contract(
                miniGameContractABI,
                process.env.REACT_APP_NFT_GAME_REGISTER
            );
            const resData = await gameNFTCont.methods
                .isAddressJoinedSeasonLeague(address || getUser().address)
                .call();
            return resData
        } catch (err) {
            console.log(err);
        }
        return 0
    }

    const joinNewestSeasonLeague = async (
        payment_token,
        price,
        callback = Function()
    ) => {
        try {
            setCallBackFunc(() => callback);
            setStatusTextModal({
                ...statusTextModal,
                pending: "Transaction pending",
                success: "Register successfully!",
                fail: "Failed to register",
                checkBalance: "Insufficient funds!",
            });
            setStatusModal({ isPending: true });
            const web3 = new Web3(RPC_BSC);
            const gameNFTCont = new web3.eth.Contract(
                miniGameContractABI,
                process.env.REACT_APP_NFT_GAME_REGISTER
            );
            const balance = await getMaxBalance(
                getUser()?.address,
                payment_token
            );
            if (balance.token < price) {
                await sleep(1000);
                setStatusModal({ isPending: false, isCheckBalance: true });
                return false;
            }
            const walletAppr = await handleApproveWallet(
                tokenABI,
                payment_token,
                process.env.REACT_APP_NFT_GAME_REGISTER,
                setStatusModal
            );
            if (walletAppr) {
            const approveData = gameNFTCont.methods
                .joinNewestSeasonLeague()
                .encodeABI();
            const dataSend = {
                signData: {
                    data: approveData,
                    from: user.address,
                    to: process.env.REACT_APP_NFT_GAME_REGISTER,
                },
            };
            await handleTransactionExWallet(
                1,
                dataSend,
                handleResultTransaction,
                ACTION_SEND_TRANSACTION.joinNewestSeasonLeague

            )}
            return true;
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };
    const handleResultTxDone = (res) => {
        if(res?.isRefesh) {
            setStatusModal({ isPending: false, isSuccess: true});
        }
    }

    
    const handleTransactionExWallet = async (
        chainId = 1,
        dataReturn,
        callback,
        action
    ) => {
        await handleTransactionExternalWallet(
            chainId,
            dataReturn,
            callback,
            action,
            library
        );
    };

    const handleApproveWallet = async (
        contractABI,
        contractAddress,//token
        marketContractAddr,//market
        setStatusModal

    ) => {
        const res = await approveWallet(
            contractABI,
            contractAddress,//token
            marketContractAddr,//market
            setStatusModal,
            library
        );
        return res
    };

    const handleApproveAllWallet = async (
        contractABI,//nftABI
        contractAddress,//market
        nftContractAddr,//nft
        setStatusModal

    ) => {
        await approveAllWallet (
            contractABI,//nftABI
            contractAddress,//market
            nftContractAddr,//nft
            setStatusModal,
            library
        );
    };

    return {
        checkLogin,
        checkAccept,
        excQueryContract,
        registerNFTGame,
        checkRegister,
        checkTurnCanBuy,
        buyTurnGame,
        checkAddressJoinedSeasonLeague,
        joinNewestSeasonLeague,
        redeemPoints,
        buyPoint,
        claimMAG
    };
};

export default useBlockchainFunc;
