import React, { useEffect } from "react";
import Maintain from "../../components/templates/maintain";
import "./style.scss";

function MainTainPage(props) {
    return (
        <Maintain />
    );
}

export default MainTainPage;
