import io from "socket.io-client";
let socket

const initiateSocket = (address) => {
  if(socket?.connected) return
  socket = io(
    process.env.REACT_APP_SOCKET_URL,
    {
      transports: ["websocket"],
      query: {
        address: address,
      },
      'reconnection': true,
      'reconnectionDelay': 5000,
    }
  );
  return socket
};
const disconnectSocket = () => {
  if (socket) socket.disconnect();
  socket = undefined
};

export { socket, initiateSocket, disconnectSocket };
