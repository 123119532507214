import React, { useEffect } from "react";
import MyTransactionsTemplate from "../../components/templates/my-transactions";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function MyTransactions(props) {
    return (
        <SiteLayout noStyle>
            <MyTransactionsTemplate />
        </SiteLayout>
    );
}

export default MyTransactions;
