import React, { useEffect } from "react";
import NFTGameHomeTemplate from "../../components/templates/home-game";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function NFTGameHomePage(props) {
  return  <SiteLayout >
            <NFTGameHomeTemplate />
          </SiteLayout>
}

export default NFTGameHomePage;
