import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Web3 from "web3";
import * as ConstantContract from "../constants/index";

export const convertRound = (numberString, decimal = 5) => {
  numberString = numberString + "";

  numberString = numberString.replace(/[\uff01-\uff6e\uff61]/g, function (ch) {
    return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
  });
  let charCode = numberString.charCodeAt(numberString.length - 1);
  if (charCode === 12290 || charCode === 129) {
    numberString = numberString.replace(/.$/, ".");
  }
  if (decimal === 0) {
    numberString = numberString.replace(/[^0-9]/g, "");
  } else {
    numberString = numberString.replace(/[^0-9.]/g, "");
  }
  if (numberString.length === 0) return 0;
  let index = numberString.indexOf(".");
  if (index > 0) {
    let string = numberString.slice(index + 1);
    string = string.replace(/[^0-9]/g, "");
    let value = parseInt(numberString.slice(0, index));
    if (string.length > decimal) {
      string = string.slice(0, decimal);
    }
    numberString = value + "." + string;
  } else if (index === 0) {
    let string = numberString.slice(index + 1);
    string = string.replace(/[^0-9]/g, "");
    let value = 0;
    if (string.length > decimal) {
      string = string.slice(0, decimal);
    }
    numberString = value + "." + string;
  } else {
    numberString = parseInt(numberString);
  }
  return numberString;
};

export const useWindowResizeMobile = (innerWidth = 576) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < innerWidth);

  useEffect(() => {
    let windowResizeListener = window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < innerWidth);
    });

    return () => window.removeEventListener("resize", windowResizeListener);
  }, []);

  return [isMobile];
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}