import React, {Fragment, useEffect} from 'react';
import "./style.scss";

 const Maintain = () => {
   return <div className="maintain-container">
     <img className="maintain-container__image" src="/images/menu/portal-game.svg"/>
     <div className="maintain-container__text">We’ll be back.</div>
     <div className="maintain-container__note">
       The site is under maintenance.
       <br/>
        Please check back soon. 
     </div>

   </div>

 }

 export default Maintain