import React, { useEffect, useState, useMemo } from "react";
import { withContext } from "../../../hook/AppContext";
import useBlockchainFunc from "../../../ultis/blockchain";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import { getUser } from "../../../ultis/common";
import { Row, Col } from "antd";
import "./style.scss";
import CustomModal from "../../elements/modal";
import RankingGame from "../../common/ranking-game";
import { getUserInfoGame, getListGameNFT } from "../../../api";
import moment from "moment";
import ReactGA4 from "react-ga4";
import queryString from "query-string";

function NFTGamePage(props) {
  const {
    history,
    getRank,
    setStatusModal,
    setStatusTextModal,
    statusTextModal,
    gameInfo
  } = props;
  const { checkAddressJoinedSeasonLeague, checkRegister } = useBlockchainFunc();
  const [showData, setshowData] = useState(true);
  const [activeSeason, setActiveSeason] = useState();
  const [activeGame, setActiveGame] = useState(1);
  const [isMobile] = useWindowResizeMobile(576);
  const [isRegister, setIsRegister] = useState(false);
  const [infoUser, setInfoUser] = useState();
  const [isRegisterSeasonLeague, setIsRegisterSeasonLeague] = useState(false)


 useEffect(async () => {
  try { 
    if(!activeSeason ){  
      const query = queryString.parse(history.location.search, {
        ignoreQueryPrefix: true,
      });
      const indexActiveGame = gameInfo?.listGame?.findIndex(el => el._id == query.game_id)
      getRank(indexActiveGame)
      setActiveGame(indexActiveGame)
      setshowData(false);
      setActiveSeason(gameInfo?.seasons[0][0]);
      // const check = await checkRegister();
      // if (check) {
      //   setIsRegister(true);
      // }
      setshowData(true);
      const userInfo = await getUserInfoGame({game_id: gameInfo?.listGame[indexActiveGame]?._id});
      setInfoUser(userInfo);
      const isAddressJoinedSeasonLeague  = await checkAddressJoinedSeasonLeague()
      setIsRegisterSeasonLeague(isAddressJoinedSeasonLeague)
    }
  } catch (er) {
    setActiveSeason();
  }
  }, [gameInfo])

 

  const renderNumber = (number) => {
    switch (number) {
      case 1:
        return <div className="reward-box__text name-prize"><img className="ranking-number" src="/images/first-prize.svg" /> Top 1</div>;
      case 2:
        return (
          <div className="reward-box__text name-prize"><img className="ranking-number" src="/images/second-prize.svg" /> Top 2</div>
        );
      case 3:
        return <div className="reward-box__text name-prize"><img className="ranking-number" src="/images/third-prize.svg" /> Top 3</div>
      default:
        return <div className="ranking-number">{number}</div>;
    }
  };

  const renderPlay = () => {
    const turn_time = 7200000 
    const total_time = infoUser?.time_fully_recovery_turn *1000
    const remainder = (total_time - Date.now()-1000) % turn_time
    const quotient = (total_time - Date.now()-1000)/ turn_time
    let turn = total_time-1000  < Date.now() ? 5 : 5 - parseInt(quotient)
    turn =  remainder > 0 ? turn -1 : turn
    const totalTurn =  (turn + infoUser?.bonus_turn) >= 0 ? turn + infoUser?.bonus_turn : `--`
    return (<>
      <div className="big-text">Play Now</div>
      {/* <div className="small-text"><img src="/images/energy.svg"/> {totalTurn}/5</div> */}
    </>
  )}

  const handleRedirect = () => {
    if(activeGame == 0) {
      if (infoUser?.is_ready_battle) {
        if (!history?.location?.pathname?.includes("/nft-game/home")) {
          history.push("/nft-game/home");
        }
      } else {
        if (!history?.location?.pathname?.includes("/nft-game/home")) {
          history.push("nft-game/home");
          setStatusModal({ isNotify: true });
          setStatusTextModal({
            ...statusTextModal,
            noti: "You must create a deck to play.",
          });
        }
      }
    } else {
      history.push(`/nft-game/game?game_id=${gameInfo?.listGame[activeGame]?._id}`);
    }
    
  };

  const register = async () => {
    const user = getUser();
    // let check = isRegister;
    // await getRank(activeGame);
    // if (!check) {
    //   check = await registerNFTGame(user?.address, gameInfo?.listGame[activeGame].game_index);
    //   ReactGA4.event("underhand_register", user?.address);
    // }
    // if (check) {
      handleRedirect();
      // setIsRegister(true);
      ReactGA4.event("underhand_play", user?.address);
    // }
  };

  const onRules = () => {
    window.open(
      "https://marketplace-monsterra.gitbook.io/marketplace-monsterra/",
      "_blank"
    );
  };

  const renderContent = useMemo (()=>{
    return( <div className={`register-game-page game-${activeGame}` }>
        <div className="layout-content">
          <div className="logo-block">
            <img src={`images/game-title-${activeGame}.png`} className="title-game" />
            {!activeSeason ? (
              <div className="no-seas-txt">No season available</div>
            ) : (
              <>
                
                <div className="reward-box">
                    {/* <div className="reward-box__title">Rewards </div>
                    <div className="reward-box__numb-txt">
                      <div className="numb-txt">
                        {activeSeason?.total_user_registered} registered players
                      </div>
                      <div
                        className="btn-block-2"
                        data-size="small"
                        onClick={() => setShowRanking(true)}
                      >
                        Rankings
                      </div>
                    </div> */}
                    {activeSeason?.start_time && (
                      <div className="time-txt">
                        {`${moment
                          .utc(moment.unix(activeSeason?.start_time))
                          .format("h:mm A UTC, MMMM D")} - ${moment
                          .utc(moment.unix(activeSeason?.end_time))
                          .format("h:mm A UTC, MMMM D, YYYY")}`}
                      </div>
                    )}
                    <div className="reward-box__item">
                      {renderNumber(1)}
                      <div className="reward-box__text">
                        <img src="/images/elo2.svg" />
                        <div>
                          {parseInt(gameInfo?.season_ranking?.ranking[0]?.elo) || 0}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="reward-box__item">
                      {renderNumber(2)}
                      <div className="reward-box__text">
                        <img src="/images/elo2.svg" />
                        <div>
                          {parseInt(gameInfo?.season_ranking?.ranking[1]?.elo) || 0}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="reward-box__item">
                      {renderNumber(3)}
                      <div className="reward-box__text">
                        <img src="/images/elo2.svg" />
                        <div>
                          {parseInt(gameInfo?.season_ranking?.ranking[2]?.elo) || 0}{" "}
                        </div>
                      </div>
                    </div>
                    
                      <div className="reward-box__detail" onClick={onRules}>{"Detail >> "}</div>
                  </div>

                {showData &&
                  (activeSeason?.is_ended ? (
                    ""
                  ) : (
                    <div className="right-btns" onClick={register}>
                      <div className="btn-block btn-active btn-play" data-size="medium">
                        <div className="txt-block">
                          {/* {!isRegister ? (
                            <>
                              <div className="big-text">Register Now</div>
                              <div className="small-text">{activeSeason?.price
                                ? `${activeSeason?.price} USDT`
                                : "FREE"}</div>
                            </>
                          ) : renderPlay} */}
                          {renderPlay()}
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
            
          </div>
        </div>
        
      </div>

  )},[activeGame,infoUser])

  return renderContent;
}

export default withContext(NFTGamePage);
