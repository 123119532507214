import React, { useEffect, useState, useMemo } from "react";
import "./style.scss";
import { Table, Pagination } from "antd"
import moment from "moment";
import {
  getPointRequest,
} from "../../../api";
import useBlockchainFunc from "../../../ultis/blockchain";
import { useUIContext } from "../../../hook/AppContext";

function MyTransactionsTemplate(props) {
  const {redeemPoints} = useBlockchainFunc()
  const [listTransactions, setListTransactions] = useState({
    data: [],
    metadata: {}
  })
  const [page, setPage] = useState(1)

  const getPointRequestList = async(page =1) => {
    const params = {
      page: page,
      limit: 10,
    }
    const  res = await getPointRequest(params)
    const data = res?.data?.map((el, index) => {
      return {
        no: 10*(page-1) + index +1,
        date: moment.utc(moment.unix(el?.time)).format("MM-DD-yyyy hh:mm:ss"),
        transactionType: el?.type == 0 ? "Buy point" : "Sell point",
        type: el?.type,
        amount: el?.type == 0 ? el?.point : el?.amount,
        icon: el?.type == 0 ? "POINTs" : "MAG",
        status: el?.status,
        txHash: el?.transaction?.tx_hash,
        id: el?._id
      }
    })
    setListTransactions({...res, data: data})
  }

  useEffect(getPointRequestList,[])

  const onClickClaimable = async(id) => {
    await redeemPoints(id, getPointRequestList)
  }

  const onClickViewDetail = (txHash) => {
    window.open(
      `${process.env.REACT_APP_BSC_SCAN}/tx/${txHash}`,
      "_blank"
    );
  }

  const onChangePage = (e) => {
    getPointRequestList(e)
    setPage(e)
  }

  const renderStatus = (item) => {
    const {type, status, id} = item
    if(type == 1 && status == 0) return <div className="status-btn yellow" >Pending</div>
    if(status == 1) return <div className="status-btn green">Successful</div>
    if(status == -1) return <div className="status-btn red">Failed</div>
  }

  const renderAction = (record) => {
    if(record?.type == 1 && record?.status == 0) return <div onClick={()=>onClickClaimable(record?.id)} className="status-btn action" >Claim</div>
    else return <div onClick={()=>onClickViewDetail(record?.txHash)} className="status-btn action" >View Details</div>
  }
 

  const column = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width:  "8%",
      render: (no) => <div>{no}</div>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width:  "16%",
      render: (date) => <div>{date}</div>,
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
      width:  "25%",
      render: (transactionType) => <div className={transactionType == "Buy point" ? "transaction-type green": "transaction-type red"}>{transactionType}</div>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width:  "20%",
      render: (amount, record) => <div className="amount"><img src={`/images/${record?.icon.toLowerCase()}-icon.svg`}/>{amount} {record?.icon} </div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width:  "16%",
      render: (status, record) => <div>{renderStatus(record)}</div>,
    },
    {
      title: "Actions",
      dataIndex: "status",
      key: "status",
      width:  "15%",
    render: (type,record) => <div>{renderAction(record)}</div>,
    },
  ]

  return (
    <div className="my-transaction">
      <div className="my-transaction__title">My Transactions</div>
      <Table
        className="transaction-table"
        columns={column}
        dataSource={listTransactions.data}
        pagination={false}
        rowKey="id"
        />
        <Pagination current={page} 
                    size="small" onChange={onChangePage} 
                    total={listTransactions?.metadata?.total} />
    </div>

    
  );
}

export default MyTransactionsTemplate;


