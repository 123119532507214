import { useEffect, useMemo, useState } from "react";
import "./style.scss";
const RACES = ["Beast", "Tectos", "Mythic", "Celest", "Chaos"];
const TIERS = ["D", "C", "B", "A", "S"];
const BodyPart = {
  Form: 0,
  Head: 1,
  Eyes: 2,
  Horns: 3,
  Tail: 4,
  Back: 5,
  FrontLeg: 6,
  BackLeg: 7,
  Mouth: 8,
  Aura: 9,
};
const BackMap = { 0: 3, 2: 2, 1: 1 };
function getId() {
  if (!window.animationMonsterraId) {
    window.animationMonsterraId = 1;
  }
  return window.animationMonsterraId++;
}
let globalPlayer = {};
function Mongen({ dna, width, height, setAnim, anim, lock, className }) {
  const [opacity, setOpacity] = useState(0);
  function getSkinList() {
    function gen(bodypart, name) {
      return `${RACES[dna[bodypart][0]]}/${TIERS[dna[bodypart][1]]}/${String(
        dna[bodypart][2]
      ).padStart(2, "0")}/${name}`;
    }
    function genBack(bodypart, name) {
      return `${RACES[dna[bodypart][0]]}/${TIERS[dna[bodypart][1]]}/${String(
        dna[bodypart][2]
      ).padStart(2, "0")}/${name}_${BackMap[dna[BodyPart.Form][0]]}`;
    }
    function genMouth() {
      return `Decor/Mouth/Mouth_${(dna[BodyPart.Mouth][2] + "").padStart(
        2,
        "0"
      )}`;
    }
    //form
    return [
      //mouth
      // gen(BodyPart.Mouth, "Mouth"),
      gen(BodyPart.Form, "Body"),
      genBack(BodyPart.Head, "Head"),
      genBack(BodyPart.Eyes, "Eyes"),
      gen(BodyPart.Horns, "Horns"),
      genBack(BodyPart.Tail, "Tail"),
      genBack(BodyPart.Back, "Back"),
      gen(BodyPart.FrontLeg, "FrontLegs"),
      gen(BodyPart.BackLeg, "BackLegs"),
      genMouth(),
    ];
  }

  function getAnimName(anim) {
    let name = RACES[dna[BodyPart.Form][0]];
    return `${name}/${name}_${anim}`;
  }
  const [player, setPlayer] = useState(null);
  const [playerId, setPlayerId] = useState(`player-hehe-${getId()}`);
  useEffect(() => {
    document.getElementById(playerId).innerHTML = "";
    let instance = new window.spine.SpinePlayer(playerId, {
      premultipliedAlpha: true,
      alpha: true,
      alpha: true,
      showControls: false,
      backgroundColor: "#00000000",
      jsonUrl: "/mongen/Pets.json",
      atlasUrl: "/mongen/Pets.atlas.txt",
      success: () => {
        globalPlayer[playerId] = instance;
        applyDNA();
      },
      error: () => {},
    });
  }, []);
  function applyDNA() {
    console.log({ globalPlayer });
    let currentPlayer = globalPlayer[playerId];
    if (!currentPlayer) return;
    setOpacity(100);
    currentPlayer.skeleton.skin.clear();
    let skins = getSkinList();
    let mainSkin = new window.spine.Skin(skins[0]);
    for (var i = 0; i < skins.length; i++) {
      if (!lock.includes(i)) {
        mainSkin.addSkin(currentPlayer.skeleton.data.findSkin(skins[i]));
      }
    }
    currentPlayer.skeleton.setSkin(mainSkin);
    currentPlayer.skeleton.setSlotsToSetupPose();

    currentPlayer.skeleton.updateCache();
    currentPlayer.config.animation = getAnimName(anim || "Idle");
    currentPlayer.playTime = 0;
    currentPlayer.setAnimation(getAnimName(anim || "Idle"), true);
    if (setAnim) {
      let anims = currentPlayer.skeleton.data.animations.map((i) => {
        return i.name.split("_")[1];
      });
      setAnim(anims);
    }
  }
  useEffect(() => {
    applyDNA();
    return () => {};
  }, [dna, anim, lock]);
  return (
    <div>
      <div id={playerId} style={{ width, height, opacity }} className={className}></div>
    </div>
  );
}
function Soulcore({ dna, width, height, className}) {
  const [opacity, setOpacity] = useState(0);
  const [instance, setInstance] = useState(null);
  const playerId = useMemo(() => {
    return `monsterra-player-${getId()}`;
  });

  function setAnim() {
    if (!instance) {
      return;
    }
    setOpacity(100);
    instance.skeleton.setSkinByName(`Skin${dna[1][0] + 1}`);
    instance.setAnimation("Hold", true);
    instance.skeleton.setToSetupPose();
  }
  useEffect(() => {
    setAnim();
  }, [instance]);
  useEffect(() => {
    let race = dna[0][0];
    let raceNameMap = ["beast", "tectos", "mystic"];
    let inst = new window.spine.SpinePlayer(playerId, {
      premultipliedAlpha: true,
      animation: "Hold",
      alpha: true,
      alpha: true,
      showControls: false,
      backgroundColor: "#00000000",
      jsonUrl: `/soulcore/${raceNameMap[race]}/Egg_Tectos_1.json`,
      atlasUrl: `/soulcore/${raceNameMap[race]}/Egg_Tectos_1.atlas`,
      success: () => {
        setInstance(inst);
      },
      error: () => {},
    });
    return () => {};
  }, []);
  return (
    <div>
      <div id={playerId} style={{ width, height, opacity }} className={className}></div>
    </div>
  );
}
export default function Anim({ dna, width, height, setAnim, anim, lock, className }) {
  if (dna.length === 2) {
    return <Soulcore dna={dna} width={width} height={height} className={className}/>;
  } else {
    return (
      <Mongen
        lock={lock}
        dna={dna}
        width={width}
        height={height}
        setAnim={setAnim}
        anim={anim}
        className={className}
      />
    );
  }
}
