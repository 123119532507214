import React, { useEffect, useState, useMemo } from "react";
import "./style.scss";
import {Row, Select, Pagination} from "antd";
import { getDefaultAssets, getAllCollection, getNftDefOwner,getNftAtkOwner,getListMiniGame} from '../../../../../api';
import { getUser} from "../../../../../ultis/common";
import { withContext } from "../../../../../hook/AppContext";
import SiteLayout from "../../../../../layouts/site-layout";
import CustomModal from "../../../../elements/modal";
import CustomButtonField from "../../../../elements/button";
import { useWindowResizeMobile } from "../../../../../hook/ultis.hook";
import InventoryItem from "./inventory-item"

const { Option } = Select;
export const defaultNftState = {
    items: [],
    page: 1,
    totalItem: 0
};

const SOULCORE = "Soulcores"


function InventoryModal(props) {
  const {listDeck,setListDeck,showInventory,setShowInventory,renderCardContent,setRenderList, deskTab} = props;
  const [listSelect, setListSelect] = useState(listDeck);
  const [length, setLength] = useState(listDeck?.length)
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState()
  const [collection, setCollection] = useState();
  const [type, setType] = useState("all");
  const [race, setRace] = useState();
  const [rarity, setRarity] = useState();
  const [nft, setNft] = useState('NFTs')
  const [isMobile] = useWindowResizeMobile(576);

  const filterNft = [
    {name: "NFTs"},
    {name: "Free"}
  ]

  const getListInventory= async (collectionAddress) =>{
    const listGame = await getListMiniGame()
    try {
      let listNft
      if(nft == filterNft[0].name){
          if(deskTab == "Defense"){
            listNft = await getNftDefOwner({
              game_id: listGame[0]?._id,
              page: page,
              limit:42,
              Race: race ? [race]:null,
              Rarity: rarity ? [rarity] : null,
              Type: (type && type != SOULCORE && type != "all" )? [type] : null,
              contract_address: type != "all" ? collectionAddress : null

            })
          }else{
            listNft = await getNftAtkOwner({
              game_id: listGame[0]?._id,
              page: page,
              limit:42,
              Race: race ? [race]:null,
              Rarity: rarity ? [rarity] : null,
              Type: (type && type != SOULCORE && type != "all" )? [type] : null,
              contract_address: type != "all" ? collectionAddress : null 
            })
          }
      }else {
        const defaultAssets = await getDefaultAssets({game_id: listGame[0]?._id})
        if(deskTab == "Defense") {listNft = {data : defaultAssets?.free_def_asset,metadata:{page: 1, total: 1}}}
        else {listNft = {data : defaultAssets?.free_atk_asset,metadata:{page: 1, total: 1}}}
      }
      const {metadata,data} = listNft
      setData(data)
      setPage(metadata?.page)
      setTotal(metadata?.total)
    }catch(err){

    }
  }

  useEffect( async() => {
    const resGame = await getListMiniGame();
    const resCollection = await getAllCollection({
      game_id: resGame[0]._id,
      chain: getUser()?.chainInfo?._id
    });
    const collectionLandplot = resCollection.find(el => el.name == "Landplots")
    const collectionSoulcore = resCollection.find(el => el.name == SOULCORE)
    setCollection({
      ...collectionLandplot, 
      collectionSoulcore: {
        name: SOULCORE,
        contract_address: collectionSoulcore?.contract_address
      }

    })
    getListInventory(collectionLandplot?.contract_address)
  }, [])

  useEffect(()=>getListInventory(type == SOULCORE ? collection?.collectionSoulcore?.contract_address : collection?.contract_address),[type, race,page, rarity, nft])

  useEffect(() => {
    setListSelect(listDeck)
    setLength(listDeck?.length)
  }, [listDeck,showInventory])

  const onClick = () =>{
    setListDeck(listSelect)
    setShowInventory(false)
    setRenderList(true)
  }

  const handleChange = (value) =>{
    setType(value)
  }

  const handleChangeNfts = (value) =>{
    setNft(value)
  }

  const handleChangeRarity = (value) =>{
    setRarity(value == "all" ? null : value)
  }

  const onClickRace = (name) =>{
    setRace(name == "all" ? null : name)
  }

  const onChangePage = (value) => {
    setPage(value)
  };

  const renderOption = () =>{
    let filter = collection?.filter
    return <>
      <Option value={collection?.collectionSoulcore?.name}>{collection?.collectionSoulcore?.name }</Option>
      {filter?.map(el =>{
        if(el?.type == "Type") return <Option value={el?.name}>{el?.name}</Option>
      })}
    </>
  }

  const renderRarity = () =>{
    let filter = collection?.filter
    return <>
      {filter?.map(el =>{
        if(el?.type == "Rarity") return <Option value={el?.name}>{el?.name}</Option>
      })}
    </>
  }

  const renderRace = () =>{
    let filter = collection?.filter
    return <>
      {filter?.map(el =>{
        if(el?.type == "Race") return <Option value={el?.name}>{el?.name}</Option>
      })}
    </>
  }

  const renderNfts = () =>{
    let filter = filterNft
    return <>
      {filter?.map(el =>{
        return <Option value={el?.name}>{el?.name}</Option>
      })}
    </>
  }

  const renderListCheckBox = useMemo(() =>{
    return (
    <div className="content-list">
      <Row>
        {data?.map(el => <InventoryItem length={length} setLength={setLength} el={el} renderCardContent={renderCardContent} listSelect={listSelect} setListSelect={setListSelect}/>)}
      </Row>
      {data?.length > 0 && <Pagination
                defaultCurrent={1}
                total={total}
                pageSize={42}
                current={page}
                onChange={onChangePage}
                showSizeChanger={false}
              />}
      </div>
    )},[listSelect,length,data,showInventory,race, type, nft])

  const renderLength = useMemo(()=>{
    return (<div className="total-quantity">
            {length}/6
            <CustomButtonField
              classNames="btn-done"
              color="green"
              size="medium"
              onClick={onClick}
            >
              {"Done"}
            </CustomButtonField>
          </div>
  )},[listSelect,showInventory, length])
  
  return (
      <CustomModal
        visible={showInventory}
        footer={null}
        onCancel={() => setShowInventory(false)}
        className="deck-modal"
        closable={false}
      >
        <SiteLayout styleMarket="master-layout__market">
        <div className="inventory-title">Inventory</div>
        <div className="inventory-header">
          <Row className="filter-container">
            <Select className="select-type" defaultValue="all" onChange={handleChange} style={{ width: isMobile ? 128 : 166, height:54 }} 
              suffixIcon={
                <img
                  className="select-icon"
                  src="/images/select-icon.svg"
                />
              } >
              <Option value="all">All Type</Option>
              {renderOption()}
            </Select>
            <Select className="select-type" defaultValue="all" onChange={handleChangeRarity} style={{ width: isMobile ? 128 : 166, height:54 }} 
              suffixIcon={
                <img
                  className="select-icon"
                  src="/images/select-icon.svg"
                />
              } >
              <Option value="all">All Rarity</Option>
              {renderRarity()}
            </Select>
            <Select className="select-type" defaultValue="all" onChange={onClickRace} style={{ width: isMobile ? 128 : 166, height:54 }} 
              suffixIcon={
                <img
                  className="select-icon"
                  src="/images/select-icon.svg"
                />
              } >
              <Option value="all">All Race</Option>
              {renderRace()}
            </Select>
            <Select className="select-type" defaultValue="NFTs" onChange={handleChangeNfts} style={{ width: isMobile ? 128 : 166, height:54 }} 
              suffixIcon={
                <img
                  className="select-icon"
                  src="/images/select-icon.svg"
                />
              } >
              {renderNfts()}
            </Select>
            {/* <Row className="select-race">
              <img onClick={()=>onClickRace("all")} src="/images/all.svg" className={!race  && "img-click"}/>
              {renderRace()}
            </Row> */}
          </Row>
          {renderLength}

        </div>
        
        {renderListCheckBox}
        
        </SiteLayout>

      </CustomModal>
   
  );
}

export default withContext(InventoryModal);
