import { useEffect, useState } from "react";
import "./style.scss";
import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Row, Col, Popover, message } from "antd";
import Web3 from "web3";
import CustomButton from "../../../../components/elements/button";
import { LOGIN_TYPE, USER_WALLET_TYPE } from "../../../../constants"
import { signInWithGoogle } from "../../../../ultis/firebase";
import { getSSOMeta } from "../../../../api";
import { login, getNonceGame, loginGame } from "../../../../api/auth";
import { useUIContext } from "../../../../hook/AppContext";

function SingleSignon (props) {
    const {
    isShowingLoginPopup,
    setIsShowingLoginPopup,
    setAuthToken,
    setRefreshToken,
    setUser,
    setStatusModal,
    isAuthenticatedUser,
    history,
    chains,
    t,
    setStatusTextModal,
    statusTextModal
  } = useUIContext();
    const [openPopup, setOpenPopup] = useState("");
    const [ssoMeta, setSSOMeta] = useState();
    const MainnetUnsupportedChain = [LOGIN_TYPE.Terra, LOGIN_TYPE.Avalanche, LOGIN_TYPE.Okex, LOGIN_TYPE.Username];

    const supportedAuthData = [
        {
            color: "#66452b",
            onSignInClick: () => connectMetamask(),
            authName: "BNB Chain",
            authExtension: "MetaMask",
            icon: "/images/wallets/bsc.png",
            //@ts-ignore
            status: !!window.ethereum,
            extensionLink: ssoMeta?.MetamaskExtension,
            disabled: MainnetUnsupportedChain.includes(LOGIN_TYPE.BSC)
        },
        {
            color: "#814441",
            onSignInClick: () => {},
            authName: "Avalanche Chain",
            authExtension: "MetaMask",
            icon: "/images/wallets/avalanche.png",
            //@ts-ignore
            status: !!window.ethereum,
            extensionLink: ssoMeta?.MetamaskExtension,
            disabled: MainnetUnsupportedChain.includes(LOGIN_TYPE.Avalanche)
        },
        {
            color: "#4a689a",
            onSignInClick: () => {},
            authName: "Okex Chain",
            authExtension: "MetaMask",
            icon: "/images/wallets/okex.png",
            //@ts-ignore
            status: !!window.ethereum,
            extensionLink: ssoMeta?.MetamaskExtension,
            disabled: MainnetUnsupportedChain.includes(LOGIN_TYPE.Okex)
        },
        {
            color: "#344763",
            onSignInClick: () => { },
            authName: "Terra Chain",
            authExtension: "Terra Station",
            icon: "/images/wallets/terra.png",
            status: !!window.terraWallets,
            extensionLink: ssoMeta?.TerraExtension,
            isDevelop: true,
            disabled: MainnetUnsupportedChain.includes(LOGIN_TYPE.Terra)
        },
    ]


    useEffect(async() =>{ 
        const meta =  await getSSOMeta()
        setSSOMeta(meta?.meta)
    },[])
    const handleLoginEx = async (data) => {
    try {
      const res = await login(LOGIN_TYPE.BSC,undefined,data.signature, data.nonce);
      const res2 = await loginGame({token : res.token, user_id: res?.userInfo?.id})
      setAuthToken(res2?.access_token);
      setRefreshToken(res2?.refresh_token)
      setUser(res.userInfo)
      setIsShowingLoginPopup(false);
      history.push('/home')
    } catch(err) {
      message.error(t(`${err?.data?.code}`))
    }
  };

  const onSignInClick = async (isRegister = false, accessToken, loginType) => {
    try {
      const res = await login(loginType, accessToken);
      const res2 = await loginGame({token : res.token, user_id: res?.userInfo?.id})
      setAuthToken(res2?.access_token);
      setRefreshToken(res2?.refresh_token)
      setUser(res.userInfo)
      history.push('/home')
    } catch (err) {
        setStatusModal({ isFailed: true });
        setStatusTextModal({
            ...statusTextModal,
            fail: t(`${err?.data?.code}`),
        });
    }
  }

    const connectMetamask = async () => {
    if (!window.ethereum) {
      // message.warning("You have to install Metamask extension!!");
      setStatusModal({
        isChangeNet: {
          type: true,
          title: "Login failed",
          value: `You have to install Metamask extension!`,
        },
      });
      return;
    }
    try {
      const web3 = new Web3(Web3?.givenProvider);
      let nonce = await getNonceGame();
      const account = await window.ethereum.request({ method: "eth_requestAccounts" });
      const sign = await web3.eth.personal.sign(nonce, account[0], "publicpassword");
      if (sign) {
        const dataLogin = {
          address: account[0],
          signature: sign,
          type: USER_WALLET_TYPE.metamask,
          nonce: nonce
        };
        handleLoginEx(dataLogin);
      }
    } catch (err) {
        setStatusModal({ isFailed: true });
        setStatusTextModal({
            ...statusTextModal,
            fail: err.message,
        });
    }
  };

    const content = () => {
        return <div className="login-container">
                <div className="login-container__text">
                    Continue with
                </div>
                {supportedAuthData.map((authData, index) => {
                    return (
                        <div  key={index} style={{position:"relative"}}>
                            <Row
                                className={authData.disabled ? "login-container__btn disabled ": "login-container__btn"}
                                style={{
                                    background: authData.color,
                                }}
                                onClick={() => {
                                    
                                    if (authData.status) {
                                        authData.onSignInClick();   
                                    } else {
                                        window.open(authData.extensionLink, "_blank");
                                    }
                                }}
                            >
                                <Col xs={4}>
                                    <img src={authData.icon} className="btn-image" alt="phantom" />
                                </Col>
                                <Col xs={14} className={"btn-name"}>
                                    { "Sign in"} with {authData.authName}
                                    {!authData.disabled && authData?.authExtension && !authData?.status && <div className="text-sm text-red-2">
                                        Download {authData.authExtension} extension
                                    </div>}
                                </Col>
                                {!authData.disabled &&<Col xs={6} className={"btn-status"}>{"Ready"}</Col>}
                            </Row>
                            {authData.disabled &&<div className={"btn-status-coming"}>{"Coming Soon"}</div>}
                        </div>
                    )
                })}

                <div className="login-container__note">
                    Extension of selected wallet will be installed
                </div>

                <div className="btn-container">
                {ssoMeta && <ReactFacebookLogin
                    cookie={true}
                    appId={ssoMeta?.FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={async (res) => {
                        let accessToken = res.accessToken;
                        await onSignInClick(false, accessToken, LOGIN_TYPE.Facebook);
                    }}
                    onFailure={console.log}
                    render={(renderProps) => (
                        <CustomButton 
                            style={{
                                background: "#344763",
                                border:"none",
                                padding: "9px 24px",
                                borderRadius: "4px",
                                margin: "10px"
                            }}
                            onClick={() => {
                                renderProps.onClick()
                            }}
                        >
                            <div className="btn-container__fb">
                                <img src={"/images/social/facebook.png"} className="" alt="facebook" />
                                {"Sign in"}
                            </div>
                        </CustomButton>
                    )}
                />}
                <CustomButton 
                    style={{
                        background: "#441f21",
                        border:"none",
                        padding: "9px 24px",
                        borderRadius: "4px",
                        margin: "10px"
                    }}
                    onClick={async () => {
                        let res = await signInWithGoogle();
                        let credential = res._tokenResponse;
                        let accessToken = credential.oauthAccessToken;
                        await onSignInClick(false, accessToken, LOGIN_TYPE.Google);
                    }}
                >
                    <div className="btn-container__fb">
                        <img src={"/images/social/google.png"} alt="google" />
                        {"Sign in"}
                    </div>
                </CustomButton>
                <CustomButton 
                    style={{
                        background: "#596189",
                        border:"none",
                        padding: "9px",
                        borderRadius: "4px",
                        margin: "10px",
                        position:"relative"
                    }}
                    // onClick={}
                    disabled={true}
                >
                    <div className="btn-container__fb coming-soon">
                        <img src={"/images/social/email.png"} className="float-left w-10 h-10 flex self-center" alt="email" />
                    </div>
                    <div className="btn-container__text">Coming soon</div>
                </CustomButton>
            </div>
        </div>
    }

    return (
        <Popover placement="bottomLeft" content={content} trigger="click" className="popover-menu">
                    <CustomButton 
                        color={"green"}
                        size="small"
                        onClick={() => {
                            setOpenPopup()
                        }}
                    >
                        {"Sign in"}
                    </CustomButton>
        </Popover>
    );
}

export default SingleSignon;