import React, { useEffect, useState,useMemo } from "react";
import {Select, Row, Col} from "antd";
import "./style.scss";
import cliTruncate from "cli-truncate";
import {getListSeasons, getRanking, getListMiniGame} from "../../../api";
import {getUser} from "../../../ultis/common";
import { withContext } from "../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import moment from "moment";


const { Option } = Select;



function RankingGame(props) {
  const {classNames, activeSeason, showReward,userInfo, gameInfo} = props;
  const [data, setData] = useState([]);
  const [listSeasons, setListSeason] = useState([])
  const [season, setSeason] = useState()
  const [yourRanking, setYourRanking] = useState();
  const [isMobile] = useWindowResizeMobile(787);

  const getListSeasonsGame = async () =>{
    const listGame = await getListMiniGame()
    const listSeason = await getListSeasons({game_id: listGame[0]?._id})
    setListSeason(listSeason)
    setSeason(activeSeason ? activeSeason?._id : listSeason[0]?._id)
  };

  const getListRanking = async () =>{
    if(season) {
      const listRanking = await getRanking({season_id: season})
      setData(listRanking?.season_ranking)
      setYourRanking({...listRanking?.user_ranking, address: getUser()?.address})
    }
  } 

  useEffect(()=>getListSeasonsGame(),[activeSeason]);
  useEffect(()=>getListRanking(),[season,userInfo,gameInfo]);

  const handleChange = (value) =>{
    setSeason(value)
  }

  const renderNumber = (number) =>{
    switch(number){
      case(1):
        return <img className={showReward ? "ranking-number number-reward":"ranking-number"} src="/images/first-prize.svg"/>
      case(2):
        return <img className={showReward ? "ranking-number number-reward":"ranking-number"} src="/images/second-prize.svg"/>
      case(3):
        return <img className={showReward ? "ranking-number number-reward":"ranking-number"} src="/images/third-prize.svg"/>
      default:
        return <div className="ranking-number">{number}</div>
    }
  }

  const renderReward = (number) =>{
    switch(number){
      case(1):
        return <div className="reward">
                  <img src="/images/token/usdt.svg"/>
                  <div>x{parseInt(activeSeason?.reward?.no1?.reward)|| 0} USDT</div>
              </div>
      case(2):
        return <div className="reward">
                  <img src="/images/token/usdt.svg"/>
                  <div>x{parseInt(activeSeason?.reward?.no2?.reward)|| 0} USDT</div>
              </div>
      case(3):
        return <div className="reward">
                  <img src="/images/token/usdt.svg"/>
                  <div>x{parseInt(activeSeason?.reward?.no3?.reward)|| 0} USDT</div>
              </div>
      default:
        return <div className="reward"></div>
    }
  }

  const renderItemRanking = (item, isYou = false) =>(
    <div className={ isYou ? "ranking-item your-ranking" : "ranking-item"}>
        <div className="number-address">
          {renderNumber(isYou ? item?.ranking : item?.rank)}
          {cliTruncate(item?.address|| "", showReward ? 20 : 12, { position: "middle" })} {isYou && "(You)"}
          </div>
          {!showReward && <div className="elo-item">{item?.elo} <img src="/images/elo.svg"/></div> }
          {showReward && renderReward(item?.rank)}
          {isYou &&<div className="your-ranking-tag">Your Ranked</div>}
        </div>

  )

  const renderListRanking = () => {
    const list = data?.ranking
    return <div className={!showReward&&"list-ranking"}>
      {list?.map((item,index) =>{
        if(!showReward) return renderItemRanking(item)
        else if(index <3) return renderItemRanking(item)
      })}
    </div>
    
  }

  const renderTimeModal = useMemo(()=>{
    const resultStart = moment.unix(data?.start_time).format("MMMM DD, YYYY")
    const resultEnd = moment.unix(data?.end_time).format("MMMM DD, YYYY")
    return  data?.start_time && <Col className="ranking-season">Season: {resultStart} - {resultEnd}</Col>
  },[data])

  const renderLucky = () =>{
    const {reward} = activeSeason
    const {lucky_reward}  =reward
    return (
      <Row className="ranking-item lucky">
        <div className="number-address">
          <div className="ranking-lucky">Lucky</div>
          <div className="ranking-lucky-address">
              {lucky_reward?.address?.map((item) => <div>{cliTruncate(item|| "", 20, { position: "middle" })}</div>)}
          </div>
        </div>
        <div className="lucky-result">
          {lucky_reward?.address?.map((item) =><div className="reward">
                                                <img src="/images/token/usdt.svg"/>
                                                <div>x{parseInt(lucky_reward?.reward)|| 0} USDT</div>
                                              </div>)}
        </div>
      </Row>
    )

  }

  return (
    <div className={"ranking-game"+ " "+classNames}>
      {/* {renderSeasons} */}
      <Row className="ranking-title-container">
        <Col className="ranking-title">{showReward ? "Rewards" : "Rankings"}</Col>
        {classNames && activeSeason &&renderTimeModal}
      </Row>
      {renderListRanking()}
      {yourRanking?.ranking > 0 && !showReward  && renderItemRanking(yourRanking, true)}
      {showReward && renderLucky()}
    </div>
  );
}

export default withContext(RankingGame);
