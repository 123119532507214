import { customAxios } from "../../ultis/custom-axios";
import { LOGIN_TYPE } from "../../constants";
import { RPC_BSC } from "../../ultis/common";


const endpoint = process.env.REACT_APP_API_MINI_GAME;
const endpointGame = process.env.REACT_APP_API_GAME;



export const connectWallet = (credentials) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/auth/connect-wallet`,
        data: credentials,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const refreshJWTToken = (credentials) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/auth/refreshtoken-minigame`,
        params: credentials,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getNonce = async (data) => {
    return customAxios({
        method: "get",
        params: { address: data?.address },
        url: `${endpoint}/auth/get-nonce`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export async function getNonceGame() {
    return customAxios({
        method: "post",
        url: `${endpointGame}/nonce/create-nonce`,
    })
    .then((res) => res.data.nonce)
    .catch((err) => {
        throw err;
    });

}

export async function login(type, social_access_token,sign,nonce, email, password) {
    localStorage.clear();
    return customAxios({
        method: "post",
        data:{
            sign,
            type,
            social_access_token,
            email,
            password,
            nonce
        },
        url: `${endpointGame}/dao/login-dao`,
    })
    .then((res) => {
        const {authInfo, token, customerInfo} = res.data
        let userInfo = {
            id: authInfo.B,
            address: authInfo.C,
            ...customerInfo
        };
        return { token, userInfo}
    })
    .catch((err) => {
        throw err;
    });
}

export const loginGame = (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/auth/login`,
        data: data,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getInfo = async () => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mini-game-mons/get-customer-info`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};
