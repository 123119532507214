import React, { useEffect, useMemo } from "react";
import "./style.scss";

function MonItem(props) {
  const { item, scoreCheck, className, isHistory } = props;
  const renderColor = useMemo(() => {
    switch (item?.rarity) {
      case "1":
        return "silver";
      case "2":
        return "green";
      case "3":
        return "blue";
      case "4":
        return "pink";
      case "5":
        return "red";
      default:
      // code block
    }
  }, [item]);

  return (
    <div className={`mon-item ${className}`}>
      <div className={`img-block ${renderColor}`}>
        <img src={item?.image||'/images/empty-item-game.svg'} className="mon-img" />
      </div>
      {!scoreCheck && <div className="point-numb">{item?.score || 0}</div>}
      {!isHistory && item?.race && item?.race != "all" && <img className="rarity-race__rarity" src={`/images/race-icon/${item?.race?.toLowerCase()}.png`}/>}
    </div>
  );
}

export default MonItem;
