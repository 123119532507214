import React, { useEffect, useState, useMemo} from "react";
import "./style.scss";
import { Row, Col, message} from "antd";
import cliTruncate from "cli-truncate";
import useBlockchainFunc from "../../../ultis/blockchain";
import { withContext } from "../../../hook/AppContext";
import { getUser }  from "../../../ultis/common";
import { getLeagueInfo, getUserLeagueRanking, getLeagueReward, getUserRanking,getListMiniGame,getListSeasons, getRanking } from '../../../api';
import moment from "moment";


function HomeTemplate(props) {
  const { history,setStatusModal, leagueInfo, setLeagueInfo, user, setStatusTextModal, statusTextModal } = props;
  const {checkAddressJoinedSeasonLeague, joinNewestSeasonLeague} = useBlockchainFunc();
  const [rewards, setRewards] = useState()
  const [register, setRegister] = useState()
  const [listGame, setListGame] = useState()
  const [listGameOutLeague, setListGameOutLeague] = useState([])
  const [userRankings, setUserRanking] = useState()
  const [isRegister, setIsRegister] = useState()

  useEffect(async() => {
    try{
      let listGame =[]
      const listG = await getListMiniGame()
      let listGameOut = []
      await Promise.all(listG.map(async el => {
        if(el.is_active) {
          // const listS = await getListSeasons({ game_id: el._id });
          listGameOut.push({...el})
        }else listGame.push(el)

      }))
      setListGame(listGame)
      setListGameOutLeague(listGameOut.reverse())
      if(listGameOut[0]?.is_maintenance) history.push("/maintain")
      // const userLeagueRanking = await getUserLeagueRanking();
      // setUserLeagueRanking(userLeagueRanking)
      // const isAddressJoinedSeasonLeague  = await checkAddressJoinedSeasonLeague()
      // setIsRegister(isAddressJoinedSeasonLeague)
      // const userRanking = await getUserRanking()
      // setUserRanking(userRanking)

    }catch(err){
    }
  },[])

  const  onClickGameItem = (url,gameOutLeague) =>{
    if(gameOutLeague && user){ 
      window.open(`${window.origin}/${url}`,"_blank")
    }else {
      setStatusModal({ isFailed: true });
      setStatusTextModal({
        ...statusTextModal,
        fail: "You must login first",
      });
    }
  }

  // const onClickRegister = async() => {
  //   if(!userLeagueRanking)history.push('/connect-wallet')
  //   else{
  //     if(!isRegister){
  //       const register = await joinNewestSeasonLeague(leagueInfo?.payment_token,leagueInfo?.price)
  //       if(register)  {
  //         setStatusModal({ isPending: false, isSuccess: true})
  //         setIsRegister(true)
  //       }
  //     }
  //   }
  // }

  // const renderRegister = useMemo(()=>{
  //   if(!isRegister){
  //     return <>
  //             <div className="btn-block" onClick={onClickRegister}>
  //               <div className="txt-block">
  //                 <div className="big-text">Register Now</div>
  //                 <div className="small-text"> fee {leagueInfo?.price} {leagueInfo?.currency}
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="register-note">Register now for a chance to win up to <span>$1,000</span> in prizes <br/> (add revenue from register fee)</div>
  //           </>
  //   }
  // },[isRegister,leagueInfo])

  const renderPrize = (el, index) =>{
    return <Row justify="space-around" align="middle">
            <Col span={3}>
            {index+1}
            </Col>
              <Col span={13} className="list-reward-prize">
                {cliTruncate(el?.address|| "", 14,{ position: "middle"})}
              </Col> 
            <Col span={8} className="list-reward-prize">
              {el?.reward}
            </Col>
          </Row>
  }

  const renderRewardItem = (item) => {
    const rewardItem = item[0]
    return <Row className="list-reward-item">
              <img src={`/images/${rewardItem?.rank_name.toLowerCase()}.svg`}/>
              <Col span={5} className="list-reward-name">
                {rewardItem?.rank_name}
              </Col>
              <Col span={17}>
                {rewardItem?.reward?.map((el, index)=> renderPrize(el, index))}
              </Col>
            </Row>
  }

  const renderListRewards = () => {
    return <div className="home-table-container">
              <Row className="title-container">
                <img className="title-container-icon" src={`/images/rewards.svg`}/>
                <Col className="title-container-text">
                  {"Rewards"}
                  <div>{register} registered players</div>
                </Col>
              </Row>
              
              <div className="list-reward">
                {rewards?.map(el => renderRewardItem(el))}
                
              </div>

            </div>
  }

  const renderGameItem = (el, gameOutLeague= false) => {
    const userRank = userRankings?.find(item => item.game_id == el?._id)
    const turn_time = 7200000 
    const total_time = userRank?.time_fully_recovery_turn *1000
    const remainder = (total_time - Date.now()-1000) % turn_time
    const quotient = (total_time - Date.now()-1000)/ turn_time
    let turn = total_time-1000  < Date.now() ? 5 : 5 - parseInt(quotient)
    turn =  remainder > 0 ? turn -1 : turn
    const totalTurn =  (turn + userRank?.bonus) >= 0 ? turn + userRank?.bonus : `--`
    return <div className={gameOutLeague ?"game-item": "game-item disabled-game"} onClick={ ()=> gameOutLeague && onClickGameItem(el.url, true)}>
            <img src={el?.back_ground}/>
            {/* {gameOutLeague &&<div className="game-item-note">{`${moment
                          .utc(moment.unix(el?.season?.start_time))
                          .format("h:mm A UTC, MMMM D")} - ${moment
                          .utc(moment.unix(el?.season?.end_time))
                          .format("h:mm A UTC, MMMM D, YYYY")}`}</div>} */}
            {/* {userLeagueRanking && gameOutLeague && <div className={gameOutLeague ? "game-item-info game-out" :"game-item-info"}>
              <Row>
                Your ranked: <div>{userRank?.rank}</div>
              </Row>
              <Row>
                ELO: <div>{userRank?.elo}</div>
              </Row>dao
              <Row>
                Energy: <div>{totalTurn}/5</div>
              </Row>
            </div>} */}
          </div>
  }
  

  return (
    <div className="home-content">
      {/* <Point gameId={listGameOutLeague[0]?._id}/> */}
      {/* <div className="home-banner"> 
        <img src="/images/bg-banner2.png" className="home-banner-image"/>
        <div className="home-banner-container">
          <img className="home-banner-container__logo" src="/images/victory.svg"/>
          <div className="home-banner-container__season">Season {leagueInfo?.league_index}: {moment.unix(leagueInfo?.start_time).format("MMM DD")} - {moment.unix(leagueInfo?.end_time).format("MMM DD")}
            <div className="season-time">- Ends in  <Countdown
                                          className="time-countdown"
                                          value={leagueInfo?.end_time *1000}
                                          format={`DDd HHh mm`}
                                          // onFinish={onFinish}
                                        />min  -</div>
          </div>
        </div>
        <Row className="home-table" justify="center">
        {renderListRewards()}
        <LeaderBoard userLeagueRanking={userLeagueRanking}/>
      </Row>
      </div>  
      {renderRegister}
      <Row className="running-title">Running <div>June 3 - July 3</div></Row> */}
      {listGameOutLeague?.map(el=> renderGameItem(el,true))}
      {listGame?.map(el => renderGameItem(el))}
      {/* <Row className="running-title">Running <div>June 3 - July 3</div></Row> */}
      
      <img src="/images/bg-footer.png" className="home-footer-image"/>
    </div>
  );
}

export default withContext(HomeTemplate);
