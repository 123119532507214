import React, { useEffect, useState,useMemo } from "react";
import { Row, Tabs, Statistic } from "antd";
import "./style.scss";
import {
  getUserInfoGame,
} from "../../../api";
import RankingGame from "../../common/ranking-game";
import MyDeckTemplate from "../my-deck";
import RuleRecovery from "./rule-recovery";
import { withContext } from "../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import queryString from "query-string";
import HistoryGame from "../history-game";
import DeckTab from "./deck-tab";
import CustomButton from "../../elements/button";
import CustomModal from "../../elements/modal";
const { Countdown } = Statistic;


function NFTGameHomeTemplate(props) {
  const { 
    history ,
    setStatusModal,
    setStatusTextModal,
    location,
    gameInfo
  } = props;
  const [userInfo, setUserInfo] = useState();
  const [activeTab, setActiveTab] = useState("Home");
  const [showModal, setShowModal] = useState(false);
  const [isMobile] = useWindowResizeMobile(787);
  const [visibleAttackConfirm, setVisibleAttackConfirm] = useState(false);
  const [disabled, setDisabled] = useState(0)

  const onCancelAttackConfirm = () => setVisibleAttackConfirm(false)
  const onClickConfirmMatch = () => {
    setVisibleAttackConfirm(false)
    history.push(`/match-result?address=${userInfo?.address}&status=matching`)
  }

  const { TabPane } = Tabs;

  const getInfoUser = async () => {
    const infoUser = await getUserInfoGame();
    setUserInfo(infoUser);
  };

  useEffect(() => {
    if(history.location.search=="?tab=Home" || !history.location.search){
      getInfoUser();
    } 
  }, [history.location.search]);

  const onChangeTab = (value) => {
    setActiveTab(value);
    history.push(`/nft-game/home?tab=${value}`);
  };

  const onClickMatch = () => {
    if(disabled <= 0){
      if(userInfo?.atk_deck?.length > 0 && userInfo?.def_deck?.length > 0){
        if(parseInt((userInfo?.time_fully_recovery_turn * 1000 - Date.now()) / 7200000) == 4) {
          setShowModal(true)
        } else {
          if(userInfo?.protect_time*1000 > Date.now()) setVisibleAttackConfirm(true)
          else history.push(`/match-result?address=${userInfo?.address}&status=matching`)
        } 
      }else {
        setStatusTextModal({noti: 'You must create a deck to play.'})
        setStatusModal({isNotify: true})
      }
    } 
  }

  const onFinishCountDown = ()=>{
    setDisabled(0)
  }

  useEffect(() => {
    setDisabled(userInfo?.delay*1000 - Date.now())
  }, [userInfo])

  useEffect(() => {
    const query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    setActiveTab(query?.tab||"Home");
  }, [location.search]);

  const renderSafeFor = useMemo(()=>{
    if(gameInfo?.infoUser?.protect_time *1000 > Date.now()) 
    return <Row className="safe-for">
            <img src="/images/safe-icon.svg"/>
            Safe for :
            <div className="safe-for-time">
              <Countdown
                className="time-countdown"
                value={gameInfo?.infoUser?.protect_time *1000}
                format={`HH : mm : ss`}
              />
            </div>
          </Row>
  },[gameInfo])
     
  const renderTabHome = () => {
    return (
      <>
        <RuleRecovery
          userInfo={userInfo}
          getInfoUser={getInfoUser}
          timeRecoveryTurn={userInfo?.time_fully_recovery_turn}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <Row className="list-competitor">
          <DeckTab deckTab={"Attack"} userGameInfo={userInfo} getInfoUser={getInfoUser}/>  
          <DeckTab deckTab={"Defense"}userGameInfo={userInfo} getInfoUser={getInfoUser}/>              
        </Row>
        {!isMobile && <RankingGame userInfo={userInfo}/>}
        
          <div className="btn-match" onClick={onClickMatch}>
            {disabled >0 ?
          <div className="time-countdown"><Countdown
              className=""
              value={disabled + Date.now()}
              format={`ss`}
              onFinish={onFinishCountDown}
          /> s</div>:
            <div className="txt-block">
              <div className="big-text">Find Match</div>
              <div className="small-text">-1 <img src="/images/energy.svg"/> </div>
            </div>
  }
          </div>
        
          {renderSafeFor}
        
      </>
    );
  };

  return (
    <div className="game-content">
      <Tabs
        activeKey={`${activeTab}`}
        onChange={onChangeTab}
        className={"tab-game"}
        destroyInactiveTabPane={true}
      >
        <TabPane key="Home" tab="Home" className="home-container">
          {renderTabHome()}
        </TabPane>
        <TabPane key="History" tab="History" className="home-container" >
          <HistoryGame />
          {!isMobile && <RankingGame userInfo={userInfo}/> }
        </TabPane>
      </Tabs>
      <CustomModal
        className="confirm-defense"
        footer={null}
        visible={visibleAttackConfirm}
        onCancel={onCancelAttackConfirm}
        closable={false}
        zIndex={10000}
      >
        <div className="d-flex align-items-center justify-content-center notify-content">
          <div>
            Shields prevent attacks from others. <br/> Are you sure you want to remove your Shields ?
          </div>
          <div className="btn-container">
            <CustomButton
            children={"Cancel"}
            classNames="red-btn btn-item"
            shape="circle-custom"
            onClick={onCancelAttackConfirm}
          />
          <CustomButton
            children={"Attack now"}
            classNames="orange-btn btn-item"
            shape="circle-custom"
            onClick={onClickConfirmMatch}
          />
          </div>
          
        </div>
      </CustomModal>
    </div>
  );
}

export default withContext(NFTGameHomeTemplate);
