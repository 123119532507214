import React, {Fragment, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import ReactGA4 from "react-ga4";

const SiteLayout = ( props ) => {
    const location = useLocation();
    const contentStyle = !props?.noStyle && (props?.styleMarket ? props?.styleMarket : "master-layout__content");
    useEffect(()=>{
        ReactGA4.send({ hitType: "pageview", page: location.pathname });
    },[])

   return (
        <Fragment>
            {props?.noLayout ? props.children : <div className="master-layout">
                <div className={contentStyle}>
                    {props.children}
                </div>
            </div> }
            
        </Fragment>
    )
};
export default SiteLayout;

export const getLayout = page => <SiteLayout>{ page }</SiteLayout>
