import React, { useEffect, useState } from "react";
import { getHistoryGame,getListSeasons } from "../../../api";
import { withContext } from "../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import { getUser } from "../../../ultis/common";
import "./style.scss";
import { Row, Col } from "antd";
import cliTruncate from "cli-truncate";
import MonItem from "../match-result/mon-item";

function HistoryGame(props) {
    const { history, gameInfo, user } = props;
    const [listM, setlistM] = useState([]);
    const [isMobile] = useWindowResizeMobile(576);


    useEffect(async () => {
        const listS = await getListSeasons({ game_id: gameInfo?.infoUser?.mini_game_user_info?.game_id })
        const res = await getHistoryGame({ season_id: listS[0]._id });
        setlistM(res);
    }, []);

    const checkRs = (rs) => {
        const { atkPoints, defPoints, atkRarityPoints, defRarityPoints } = rs;
        if (atkPoints === defPoints) {
            return atkRarityPoints > defRarityPoints;
        }
        return atkPoints > defPoints;
    };

    const renderRsHist = (data) => {
        const winRs = data.round_points.filter((el) => checkRs(el));
        return (
            <Col sm={3} xs={8} className="result-container">
                <div className="result-txt">
                    {data?.atk_elo_change == data?.def_elo_change ? "Draw " :((data?.atk_address.toLowerCase() ===
                        user?.address.toLowerCase() &&
                        data?.atk_elo_change > 0) ||
                    (data?.def_address.toLowerCase() ===
                        user?.address.toLowerCase() &&
                        data?.def_elo_change >= 0) ? (
                        <div className="vic">Victory</div>
                    ) : (
                        <div className="fail">failure</div>
                    ))}
                </div>
                <div className="round-numb-txt">{`${winRs.length} - ${
                    data.round_points.length - winRs.length
                }`}</div>
            </Col>
        );
    };

    const onGoHistory = (item) => {
        history.push(`/match-result?battle_id=${item?._id}&status=history`);
    };

    const getHighestRarity = (list) => {
        const rs = list.reduce(function (a, b) {
            return a?.rarity > b?.rarity ? a : b;
        }, list[0]);
        return rs;
    };

    return (
        <div className="history-game-page">
            <div className="list-match">
                {listM.map((el) => (
                    <Row
                        justify="space-between"
                        className="row-his"
                        onClick={() => onGoHistory(el)}
                    >
                        <>
                            <Col span={20}>
                                <Row
                                    justify="space-between"
                                    className="row-result"
                                    key={el?._id}
                                >
                                    <Col sm={10} xs={8}>
                                        <Row>
                                            {el?.atk_address.toLowerCase() ==
                                                    user?.address.toLowerCase() ?  <Col
                                                sm={16}
                                                xs={24}
                                                className="flex-center address-txt end"
                                            >
                                                {!isMobile && cliTruncate(
                                                    el?.atk_address,
                                                    12,
                                                    {
                                                        position: "middle",
                                                    }
                                                )}
                                                {isMobile ? "You" : 
                                                    " (You)"}
                                            </Col> : 
                                             <Col
                                                sm={16}
                                                xs={24}
                                                className="flex-center address-txt end"
                                            >
                                                {cliTruncate(
                                                    el?.atk_address,
                                                    12,
                                                    {
                                                        position: "middle",
                                                    }
                                                )}
                                            </Col>}
                                            <MonItem
                                                className="small center"
                                                scoreCheck
                                                item={getHighestRarity(el?.nft_atk_data)}
                                                isHistory={true}
                                            />
                                        </Row>
                                    </Col>
                                    {renderRsHist(el)}
                                    <Col sm={10} className="player-right" xs={8}>
                                        <Row>
                                            <MonItem
                                                className="small center"
                                                scoreCheck
                                                item={getHighestRarity(
                                                    el?.nft_def_data
                                                )}
                                                isHistory={true}
                                            />{" "}
                                            {el?.def_address.toLowerCase() ==
                                                    user?.address.toLowerCase() ? <Col
                                                sm={16}
                                                xs={24}
                                                className="flex-center address-txt"
                                            >
                                                {!isMobile && cliTruncate(
                                                    el?.def_address || "",
                                                    12,
                                                    {
                                                        position: "middle",
                                                    }
                                                )}
                                                {isMobile ? "You" :
                                                    " (You)"}
                                            </Col> :
                                            <Col
                                                sm={16}
                                                xs={24}
                                                className="flex-center address-txt"
                                            >
                                                {cliTruncate(
                                                    el?.def_address || "",
                                                    12,
                                                    {
                                                        position: "middle",
                                                    }
                                                )}
                                            </Col>}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                span={4}
                                className="elo-change-txt flex-center"
                            >
                                ELO{" "}
                                {el?.atk_address.toLowerCase() ===
                                    user?.address.toLowerCase() &&
                                    (el?.atk_elo_change > 0
                                        ? "+" + el?.atk_elo_change
                                        : el?.atk_elo_change)}
                                {el?.def_address.toLowerCase() ===
                                    user?.address.toLowerCase() &&
                                    (el?.def_elo_change > 0
                                        ? "+" + el?.def_elo_change
                                        : el?.def_elo_change)}
                            </Col>
                        </>
                    </Row>
                ))}
            </div>
        </div>
    );
}

export default withContext(HistoryGame);
