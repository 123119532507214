import { customAxios } from "../ultis/custom-axios";

const endpoint = process.env.REACT_APP_API_MINI_GAME
const endpointGame = process.env.REACT_APP_API_GAME


export const getAllCollection = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/collections/get-all-collection`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getListGame = async () => {
    return customAxios({
        method: "get",
        url: `${endpoint}/game/list-game`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const sendTx = (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/transaction/send-tx`,
        data,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getByUrl = async (url) => {
    return customAxios({
        method: "get",
        url: url,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};


export const getNoti = async (page, limit) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/notification/get-notifications`,
        params: { page, limit },
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const readAllNotifications = async (params) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/notification/read-all-notifications`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}


export const getChains = async () => {
    return customAxios({
        method: "get",
        url: `${endpoint}/chains/list-chains`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getListMiniGame = async () => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mini-game/list-game`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const matchGame = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/mini-game/match`,
        data,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const redeemPoints = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/mini-game-mons/redeem-points`,
        data,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getListSeasons = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mini-game/get-list-season`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getRanking = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mini-game-mons/get-ranking`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getListGameNFT = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mini-game/list-game`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const saveAtkDeck = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/mini-game/save-atk-deck`,
        data,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const saveDefDeck = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/mini-game/save-def-deck`,
        data,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}


export const getUserInfoGame = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mini-game/get-user-info`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getHistoryGame = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mini-game/get-history`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getNftOwnerGame = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mini-game/nft-owner`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getListTokens = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/chains/list-tokens`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getListHistory = async (params) =>{
    return customAxios({
        method: "get",
        url: `${endpoint}/transaction-history/get-history`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getNftAtkOwner = (params) => {
    return customAxios({
        method: "get",
        params,
        url: `${endpoint}/mini-game/nft-atk-owner`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getNftDefOwner = (params) => {
    return customAxios({
        method: "get",
        params,
        url: `${endpoint}/mini-game/nft-def-owner`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getLeagueInfo = (params) => {
    return customAxios({
        method: "get",
        params,
        url: `${endpoint}/league/league-info`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getLeagueRanking = (page, limit) => {
    return customAxios({
        method: "get",
        params:{ page, limit},
        url: `${endpoint}/league/league-ranking`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getLeagueReward = (params) => {
    return customAxios({
        method: "get",
        params,
        url: `${endpoint}/league/league-reward`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getDefaultAssets = (params) => {
    return customAxios({
        method: "get",
        params,
        url: `${endpoint}/mini-game/get-default-assets`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getUserLeagueRanking = () => {
    return customAxios({
        method: "get",
        url: `${endpoint}/league/user-league-ranking`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
} 

export const getUserRanking = () => {
    return customAxios({
        method: "get",
        url: `${endpoint}/league/user-ranking`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
}
export const getPointRequest = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mini-game-mons/get-point-request`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const claimRedeemPoints = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/mini-game-mons/claim-redeem-points`,
        data,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const buyPoints = async () => {
    return customAxios({
        method: "post",
        url: `${endpoint}/mini-game-mons/buy-points`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const sellPoints = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/mini-game-mons/redeem-points`,
        data
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getSSOMeta = async () => {
    return customAxios({
        method: "get",
        url: `${endpointGame}/customer/get-SSO-meta`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}
