import React, { useEffect, useMemo, useState } from "react";
import { withContext } from "../../../../hook/AppContext";
import "./style.scss";
import cliTruncate from "cli-truncate";
import copy from "copy-to-clipboard";
import { Menu } from "antd";
import {
  getUser,
  getWalletType
} from "../../../../ultis/common";
import { USER_WALLET_TYPE, walletConnector, WALLET_TYPE } from "../../../../constants";
import { useWindowResizeMobile } from "../../../../hook/ultis.hook";
import CustomDropdown from "../../../../components/elements/dropdown-custom";
import {useWallet} from '@terra-money/wallet-provider'
import { useWeb3React } from '@web3-react/core';
import CustomButton from "../../../../components/elements/button";
import useBlockchainFunc from "../../../../ultis/blockchain";

function UserLoggedIn(props) {
  const {
    user,
    logout,
    gameInfo,
    getGameUserInfo,
    getBalanceWallet,
    chains,
    setUser
  } = props;
  const [isMobile] = useWindowResizeMobile(769);
  const {disconnect} = useWallet();
  const { deactivate, active } = useWeb3React();
  const { claimMAG } = useBlockchainFunc()

  const  format = (n) => {
    return  n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }

  const signOut = async () => {
    if (getWalletType() === USER_WALLET_TYPE.walletConnect) {
      walletConnector.close()
      deactivate()
    }
    logout();
    disconnect()
    window.location.reload();
  };
  const handleClaimMAG = async () => {
    await claimMAG(getBalanceWallet)
  }

  const copyCodeToClipboard = () => {
    copy(user?.address);
  };

  useEffect(async () => {
    if (getWalletType() === USER_WALLET_TYPE.walletConnect) {
      if(!active) {
        signOut()
      } else {
      }
    }
    getGameUserInfo()
  }, []);

  useEffect(() => {
    let chainInfo
    if(chains?.length && user?.address) {
      switch(user.walletType) {
        case WALLET_TYPE.bsc:
          chainInfo = chains?.find(el => el?.sort_name.includes("BSC"))
          break;
        case WALLET_TYPE.terra:
          chainInfo = chains?.find(el => el?.sort_name.includes("Terra"))
          break;
        default:
          // code block
      }
    }
    if (chainInfo) {
      setUser({
        ...user,
        chainInfo,
      });
    }
  }, [chains]);
  
  const renderBalance = useMemo(()=><span>{gameInfo?.magBalance && format(Number(Number(gameInfo?.magBalance))) || 0}</span>, [gameInfo])

  const dropdownMenu = (
    <Menu className="dropdown-menu-login">
      {/* <Menu.Item key="inven">
        <a href={"#/inventory"} className="item-text">
          Inventory
        </a>
      </Menu.Item>
      <Menu.Divider></Menu.Divider>
      <Menu.Item key="my-listing">
        <a href={"#/my-listing"} className="item-text">
          My Listing
        </a>
      </Menu.Item>
      <Menu.Divider></Menu.Divider> */}
      {isMobile && <><Menu.Item>
        <div className="address-block chain-block">
          <img className="logo-token" src={getUser()?.chainInfo?.image} />
          <span>{getUser()?.chainInfo?.sort_name}</span>
        </div>
      </Menu.Item>
      <Menu.Divider></Menu.Divider></>}
      <Menu.Item
        key="user-address"
        className="copy-address"
        onClick={copyCodeToClipboard}
      >
        {user?.address
          ? cliTruncate(user?.address, 10, { position: "middle" })
          : ""}
        <img src="/images/copy.svg" />
      </Menu.Item>
      <Menu.Divider></Menu.Divider>
      <Menu.Item key="sign-out">
        <a onClick={signOut} className="item-text">
          Sign out
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="user-logged-block">
      <div className="balance-block">
        {renderBalance}
        <img className="logo-token" src="/images/mag.svg" />
      </div>
      <div className="dropdown-menu">
        <CustomDropdown
          overlay={dropdownMenu}
          placement="bottomRight"
          trigger={["click"]}
        >
          {!isMobile ? <div className="address-block">
            {user?.address
              ? cliTruncate(user?.address, 10, { position: "middle" })
              : ""}
          </div> : 
          <img src="/images/user-mobile.svg"/>}
        </CustomDropdown>
      </div>
      
    </div>
  );
}

export default withContext(UserLoggedIn);
