import React, { useEffect, useState } from "react";
import "./style.scss";
import { Tabs } from "antd";
import { useUIContext, withContext } from "../../../hook/AppContext";
import CustomButtonField from "../../elements/button";
import useBlockchainFunc from "../../../ultis/blockchain";
import RuleRecovery from "../../templates/home-game/rule-recovery";
import queryString from "query-string";
import { buyPoints, redeemPoints } from "../../../api";

function NFTIngameTemplate(props) {
  const {userInfo, setCallBackGameFunc, gameInfo, history} = props;
  const { buyTurnGame, redeemGamePoint, buyPointGame } = useBlockchainFunc();
  const [contentRef, setContentRef] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [game, setGame] = useState()

  // const buyMoreTurnGame = (quantity) => {
  //   await buyTurnGame(quantity,renderTotalPrice(),getInfoUser)
  // }
  const updateEnergy = () => {
    contentRef?.contentWindow?.unityGame?.SendMessage('UILobby', 'UpdatePoint')
  }

  const buyPoint = async () => {
    const res = await buyPoints();
    console.log(res);
    buyPointGame(res, updateEnergy)
  }

  const redeemPoint = async (amount) => {
    const res = await redeemPoints({amount});
    console.log(res);
    redeemGamePoint(res, updateEnergy)
  }

  useEffect(() => {
    const query = queryString.parse(history.location.search, {
        ignoreQueryPrefix: true,
    });
    const activeGame = gameInfo?.listGame?.find(el => el._id == query.game_id)
    setGame(activeGame)
  },[gameInfo])

  useEffect(() => {
    if (contentRef?.contentWindow && !contentRef?.contentWindow?.buyTurnGame) {
      contentRef.contentWindow.buyTurnGame = buyPoint;
      contentRef.contentWindow.ingame = true;
      window.ingame = true;
      window.buyTurnGame = buyPoint
      contentRef.contentWindow.redeemPoint = redeemPoint;
      window.redeemPoint = redeemPoint
    }
  }, [contentRef]);

  return (
    <div>
      {game?.url && <iframe
        ref={setContentRef}
        src={window.origin+game?.url}
        style={{ border: "none", width: "98vw", height: "91vh" }}
      ></iframe>}
      <RuleRecovery userInfo={userInfo} gameIndex={2} isIngame={true} showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}

export default withContext(NFTIngameTemplate);
