import React, { useState, useMemo,useEffect } from "react";
import "./style.scss";
import { Statistic, Row, Col } from "antd";
import {
  saveAtkDeck, 
  saveDefDeck,
  getUserInfoGame
} from "../../../../api";
import { useUIContext } from "../../../../hook/AppContext";
import {DEFAULT_ITEMS_DECK} from "../../../../constants";
import InventoryModal from "./inventory-modal";
import CustomButtonField from "../../../elements/button";
import CustomButton from "../../../elements/button";
import CustomModal from "../../../elements/modal";


function DeckTab(props) {
  const {
    statusTextModal,
    setStatusTextModal,
    setStatusModal,
    getGameUserInfo
  } = useUIContext();
  const {deckTab,getInfoUser} = props;
  const [listDeck, setListDeck] = useState([]);
  const [showInventory, setShowInventory] = useState(false);
  const [renderList, setRenderList] = useState(false);
  const [edit, setEdit] = useState(false)

  const getList = async() =>{
    const infoUser = await getUserInfoGame();
    if(deckTab == "Attack")setListDeck(infoUser?.atk_deck)
    else setListDeck(infoUser?.def_deck)
  }

  useEffect(()=>{
    getList()
  },[])

  const onClickEmpty = () => {
    setShowInventory(true)
  }
  const onClickConfirm = async() =>{
    if(listDeck?.length > 0){
      setStatusTextModal({
        ...statusTextModal,
        pending: "Save pending",
        success: "You have successfully saved deck!",
        fail: "Failed to save",
      });
      setStatusModal({ isPending: true });
      try{
        const listId = listDeck?.map(el=> el?.id?._id ? el?.id?._id : el?._id)
        let res
        if(deckTab == "Defense"){
          res= await saveDefDeck({list_nft: listId})
        }else res = await saveAtkDeck({list_nft: listId})
        if(res) {
          setStatusModal({ isPending: false, isSuccess: true })
        }
        setEdit(false)
        // getGameUserInfo()
        getInfoUser()
        getList()
      }catch(e) {
        setStatusModal({ isPending: false, isFailed: true });
      }
    }else {
      setStatusModal({ isNotify: true });
      setStatusTextModal({
        ...statusTextModal,
        noti: "Requires at least 1 NFT set up in the deck.",
      });
    }
  }

  const  onClickRemove = (el) =>{
    let list = listDeck
    const index = list.indexOf(el);
    if (index > -1) {
      list.splice(index, 1); 
    }
    setListDeck(list)
    setRenderList(true)
  }

  const onClickEdit = () =>{
    setEdit(true)
  }

  const onClickCancel = () => {
    getList()
    setEdit(false)
  }

  const onFinish = (val) =>{
    setEdit(true)
  }

  const renderEmptyItem = (total) =>{
    let list = []
    for(let i=0; i < total; i++) {
      if(!edit){
        list[i] = renderCardItem()
      }else list[i] = <div className="item-empty" onClick={onClickEmpty}/>
    }
    return list
  }
  

  const renderCardContent = (el) =>{
    const attributes = el?.attributes
    const rarity = attributes?.find((el) => el.trait_type === "Rarity");
    const race = attributes?.find((el) => el.trait_type === "Race");
    return (
      <div className="card-deck__content item-deck__content">
        <img className="image-card" src={el? el.image : "/images/avatar-empty.svg"}/>
        {el && <div>Score: {el.score}</div>}
        <div className="rarity-race">
        {rarity && <img className="rarity-race__rarity" src={`/images/rarity-icon/${rarity?.value?.toLowerCase()}.svg`}/>}
        {race && race?.value != "All" && <img className="rarity-race__rarity" src={`/images/race-icon/${race?.value?.toLowerCase()}.png`}/>}
      </div>
      </div>
    ) 
  }

  const renderCardItem = (el) => (
    <div className="item-deck" >
      <div className={edit && "item-deck-container"}> 
      {el ? <div className="id-card">ID: {el?.id ? el.id.nft_info?.token_id : el?.nft_info?.token_id }</div>: <div className="id-card card-empty"/>}
      {renderCardContent(el?.id ? el.id : el)}
      </div>
      { edit && <CustomButtonField
        classNames="btn-remove"
        color="red"
        size="small"
        onClick={()=>onClickRemove(el)}
      >
        {"Remove"}
      </CustomButtonField>}
    </div>
  )

  const renderListDeck = useMemo(() => {
    setRenderList(false)
    const list = listDeck
    return <div className="list-deck">
      {list?.map((el,index)=> renderCardItem(el,index))}
      {renderEmptyItem(DEFAULT_ITEMS_DECK - list?.length)}
    </div>
   
  },[listDeck?.length, renderList,listDeck, deckTab, edit])

  
  const renderContent = () => (
    <div className="deck-tab">
      <Row className="action-container">
        <Col>{deckTab}</Col>
         <Row>
          {edit ?
            <>
            <div className="action-container-cancel" onClick={onClickCancel}>Cancel</div>
            <div className="action-container-confirm" onClick={onClickConfirm}>Confirm</div>
            </>
            : 
            <div className="action-container-edit" onClick={onClickEdit}>Edit</div>
          }
        </Row> 
      </Row>
      {renderListDeck}
      <InventoryModal deskTab={deckTab} setRenderList={setRenderList}showInventory={showInventory} setShowInventory={setShowInventory} listDeck={listDeck} setListDeck={setListDeck} renderCardContent={renderCardContent}/>
    
      
    </div>
    
  );

  return renderContent()
}

export default DeckTab;
