import React, { useEffect, useState, useMemo } from "react";
import { Col, Checkbox} from "antd";


function InventoryItem(props){
  const {renderCardContent, el, listSelect, setListSelect,length, setLength} =props;
  const [checked, setChecked] = useState();
  const [disable, setDisable] = useState(listSelect?.length >= 6 && !listSelect?.find(item => item._id == el._id))

  useEffect(() => {
    setDisable(listSelect?.length >= 6 )
  }, [listSelect, length,el])

  useEffect(() => {
    setChecked(listSelect?.some(item => item._id == el._id || item?.id?._id == el._id))
  }, [el, length,listSelect])

  const onClick = () =>{
    if (listSelect?.length < 6 || checked){
      let list = listSelect
      const itemAdd = list.find(
        (item) =>
          item._id === el._id || item?.id?._id === el._id
      );
      if (itemAdd) {
        setChecked(false)
        if(itemAdd?.id?._id){
          list = list.filter((item) =>  item?.id?._id !== itemAdd?.id?._id);
        }else list = list.filter((item) => item._id !== itemAdd._id );
        
      } else {
        list.push(el);         
        setChecked(true)
      }
      setLength(list?.length)
      setListSelect(list)
    }
  }

  const renderCheckBox = useMemo(() =>{
    return <Checkbox checked={checked} disabled={disable}/>
  },[listSelect, checked,length, disable, el])

  return <Col className="inventory-card" onClick={onClick}>
          <div className="box-id">
            <div className="id-card">ID: {el.nft_info?.token_id}</div>
            {renderCheckBox}
          </div>
          {renderCardContent(el)}
        </Col>

}

export default InventoryItem