import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

const MaxUint = '115792089237316195423570985008687907853269984665640564039457584007913129639935'


const ACTION_SEND_TRANSACTION = {
    allow: -1,
    sale: 0,
    buy: 1,
    cancel: 2,
    update: 3,
    makeOffer:4,
    acceptOffer:5,
    cancelOffer: 6,
    updateOffer:7,
    createBundle: 8,
    buyBundle:9,
    cancelBundle: 10,
    buyTurn: 11,
    convertNFT: 12,
    joinNewestSeasonLeague: 14,
    buyPoints: 15,
    redeemPoints: 16
}

const THEME = {
    dark: 'dark',
    light: 'light'
}

const signMessage =
  "Welcome. By signing this message you are verifying your digital identity. This is completely secure and does not cost anything!";

const USER_WALLET_TYPE = {
    metamask: 1,
    walletConnect: 2,
    terraStation: 3,
    walletConnectTerra: 4,
}

const WALLET_TYPE = {
    bsc: 1,
    terra: 2
}

const FEE_TYPE = {
    default:1,
    bundle:2
}

const CSS_TYPE = {
    slideRange: "slide_range"
}

const PRICE_BUY_POINTS = 1000
const CONTRACT_ADDRESS_CLAIM_MAG = "0x9BA61C4B6d433B57e4152Ad37Ca5e43e8fA56479"

export {
    MaxUint,
    THEME,
    USER_WALLET_TYPE,
    ACTION_SEND_TRANSACTION,
    signMessage,
    WALLET_TYPE,
    FEE_TYPE,
    CSS_TYPE,
    PRICE_BUY_POINTS,
    CONTRACT_ADDRESS_CLAIM_MAG
}

export const DEFAULT_ITEMS_PER_PAGE = 40;

export const LOGIN_TYPE = {
    BSC : 0,
    Terra: 1,
    Username: 2,
    Avalanche: 3,
    Facebook: 4,
    Google: 5,
    GoogleMobile: 6,
    SavedToken: 7,
    Okex: 8,
    Farmer: 9,
}

export const EVENT_SOCKET = [
    "updateNotification",
    "box/buy",
    "box/unBox",
    "bundle/buy",
    "bundle/cancel",
    "bundle/list",
    "nft/list",
    "nft/bid",
    "nft/accept",
    "nft/buy",
    "nft/cancelOrder",
    "nft/cancelBid",
    "nft/updateBid",
    "nft/updateOrder",
    "mini-game/buy-turn",
    "nft/convert",
    "mini-game/match",
    "mini-game/buy-points", 
    "mini-game/redeem-points"
]

export const walletConnector = new WalletConnectConnector({
    qrcode: true,
    rpc: {[56]: 'https://bsc-dataseed1.binance.org',[97]: 'https://data-seed-prebsc-1-s1.binance.org:8545/'},
    pollingInterval: 12000,
})

export * as marketABI from './ABI/market.json'
export * as tokenABI from './ABI/token.json'
export * as nftABI from './ABI/nft.json'
export * as paymentABI from './ABI/payment.json'
export * as miniGameABI from './ABI/miniGameContract.json'

export const VERSION = 3

export const DEFAULT_ITEMS_DECK = 6;
