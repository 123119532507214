import React, { useEffect, useState } from "react";
import { message, Form } from "antd";
import "./style.scss";
import { withContext } from "../../../hook/AppContext";
import Web3 from "web3";
import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { signInWithGoogle } from "../../../ultis/firebase";
import * as AuthAPI from "../../../api/auth";
import { signMessage, USER_WALLET_TYPE, walletConnector, LOGIN_TYPE } from "../../../constants";
import CustomModal from "../../../components/elements/modal";
import { useUIContext } from "../../../hook/AppContext";
import { checkTypeWallet } from "../../../ultis/common";
import { getSSOMeta } from "../../../api";
import { login, getNonceGame } from "../../../api/auth";
import { useWeb3React } from '@web3-react/core';
import CustomButton from "../../elements/button";

function ConnectWallet(props) {
  const {
    isShowingLoginPopup,
    setIsShowingLoginPopup,
    setAuthToken,
    setRefreshToken,
    setUser,
    setStatusModal,
    isAuthenticatedUser,
    history,
    chains,
    t
  } = useUIContext();
  const [accounts, setAccounts] = useState([]);
  const [formData, setFormData] = useState({});
  const [ssoMeta, setSSOMeta] = useState()
  const { chainId, activate, library, account } = useWeb3React()

  const getSignMessage = async (address) => {
    const res = await AuthAPI.getNonce({ address });
    if (res.data > 0) {
      return `${signMessage} Nonce: ${res.data}`;
    }
    return `${signMessage}`;
  };

  const connectMetamask = async () => {
    if (!window.ethereum) {
      // message.warning("You have to install Metamask extension!!");
      setStatusModal({
        isChangeNet: {
          type: true,
          title: "Login failed",
          value: `You have to install Metamask extension!`,
        },
      });
      return;
    }
    try {
      const web3 = new Web3(Web3?.givenProvider);
      let nonce = await getNonceGame();
      const account = await window.ethereum.request({ method: "eth_requestAccounts" });
      const sign = await web3.eth.personal.sign(nonce, account[0], "publicpassword");
      if (sign) {
        const dataLogin = {
          address: accounts[0],
          signature: sign,
          type: USER_WALLET_TYPE.metamask,
          nonce: nonce
        };
        setFormData(dataLogin);
        handleLoginEx(dataLogin);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const connectWalletCon = async () => {
    // message.info("Coming soon!!!")
    // return
    
    try {
      // Create a connector
      
      await activate(walletConnector)
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if(library) {
      signWalletmess(library)
    }
  }, [library])

  useEffect(async() =>{ 
    const meta =  await getSSOMeta()
    setSSOMeta(meta?.meta)
  },[])

  const signWalletmess = async (connector) => {
    try {
      let nonce = await getNonceGame();
      const web3 = new Web3(Web3?.givenProvider);
      const account = await window.ethereum.request({ method: "eth_requestAccounts" });
      const sign = await web3.eth.personal.sign("nonce", account[0], "publicpassword");
      if (sign) {
        const dataLogin = {
          address: account,
          signature: sign,
          type: USER_WALLET_TYPE.walletConnect,
          nonce:nonce
        };
        setFormData(dataLogin);
        handleLoginEx(dataLogin);
      }
    } catch (err) {
      message.error(err.message);
    }
  }

  useEffect(() => {
    let chainInfo
    if(chains?.length) {
      switch(formData.type) {
        case USER_WALLET_TYPE.metamask:
        case USER_WALLET_TYPE.walletConnect:
          chainInfo = chains?.find(el => el?.sort_name.includes("BSC"))
          break;
        case USER_WALLET_TYPE.terraStation:
        case USER_WALLET_TYPE.walletConnectTerra:
          chainInfo = chains?.find(el => el?.sort_name.includes("Terra"))
          break;
        default:
          // code block
      }
    }
    if (formData.address && chainInfo) {
      setUser({
        address: formData.address?.toLowerCase(),
        chainInfo,
        walletType: checkTypeWallet(formData.type),
        walletCon: formData.type
      });
    }
  }, [formData, chains])

  const handleLoginEx = async (data) => {
    try {
      const res = await login(LOGIN_TYPE.BSC,undefined,data.signature, data.nonce);
      setAuthToken(res.token);
      setUser(res.userInfo)
      setIsShowingLoginPopup(false);
      history.push('/home')
    } catch(err) {
      message.error(t(`${err?.data?.code}`))
    }
  };

  const onSignInClick = async (isRegister = false, accessToken, loginType) => {
    try {
      const res = await login(loginType, accessToken);
      setAuthToken(res.token);
      setUser(res.userInfo)
      history.push('/home')
    } catch (err) {
      message.error(t(`${err?.data?.code}`))
    }
  }

  return (
    <CustomModal
      visible={true}
      footer={null}
      onCancel={() => setIsShowingLoginPopup(false)}
      className={`dark-modal connect-wallet-modal`}
      closeIcon={<></>}
    >
      <div className="popup-content">
        <div className="title-popup">Continue with</div>
        <div className="wallet-box-line"></div>
        <div className="chain-title">Binance Smart Chain</div>
        <div
          className="wallet-connect-btn"
          onClick={() => {
            setIsShowingLoginPopup(false);
            connectMetamask();
          }}
        >
          <img src="/images/metamask-icon.svg"></img>
          <div className="wallet-type">Metamask</div>
          <img className="wallet-arrow" src="/images/arrow-right.svg"></img>
        </div>
        <div
          className="wallet-connect-btn"
          onClick={() => {
            setIsShowingLoginPopup(false);
            connectWalletCon();
          }}
        >
          <img className="wallet-icon" src="/images/wallet-connect.svg"></img>
          <div className="wallet-type">WalletConnect</div>
          <img className="wallet-arrow" src="/images/arrow-right.svg"></img>
        </div>
      <div className="btn-container">
        {ssoMeta && <ReactFacebookLogin
            cookie={true}
            appId={ssoMeta?.FACEBOOK_APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            callback={async (res) => {
                let accessToken = res.accessToken;
                await onSignInClick(false, accessToken, LOGIN_TYPE.Facebook);
            }}
            onFailure={console.log}
            render={(renderProps) => (
                <CustomButton 
                    style={{
                        background: "#344763",
                        border:"none",
                        padding: "9px 24px",
                        borderRadius: "4px",
                        margin: "10px"
                    }}
                    onClick={() => {
                      renderProps.onClick()
                    }}
                >
                    <div className="btn-container__fb">
                        <img src={"/images/social/facebook.png"} className="" alt="facebook" />
                        {"Sign in"}
                    </div>
                </CustomButton>
            )}
        />}
        <CustomButton 
          style={{
              background: "#441f21",
              border:"none",
              padding: "9px 24px",
              borderRadius: "4px",
              margin: "10px"
          }}
          onClick={async () => {
              let res = await signInWithGoogle();
              let credential = res._tokenResponse;
              let accessToken = credential.oauthAccessToken;
              await onSignInClick(false, accessToken, LOGIN_TYPE.Google);
          }}
      >
          <div className="btn-container__fb">
              <img src={"/images/social/google.png"} className="float-left w-10 h-10 flex self-center" alt="google" />
              {"Sign in"}
          </div>
      </CustomButton>
      </div>
      </div>
    </CustomModal>
  );
}

export default ConnectWallet;
